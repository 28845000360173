<template>
  <div
    class="avtar inline-block overflow-hidden"
    :style="{
      'background-color': props.init.avatar ? '' : backgroundColor,
      width: props.size + 'px',
      height: props.size + 'px',
      'line-height': props.size + 'px',
      color: props.textColor,
    }"
  >
    <el-avatar v-if="props.init.avatar" class="img" :size="size" :src="props.init.avatar" />
    <!-- <img v-if="props.init.avatar" :src="props.init.avatar" alt="" style="border-radius: 50%" /> -->
    <span v-else>{{ showName }}</span>
  </div>
</template>

<script setup lang="ts">
import { ref, reactive, onMounted, getCurrentInstance } from "vue";
import { message } from "@/utils/message";
const props = defineProps({
  from: {
    type: String,
    default: "",
  },
  init: {
    type: Object,
    default: null,
  },
  color: {
    type: String,
    default: "",
  },
  size: {
    type: Number,
    default: 44,
  },
  textColor: {
    type: String,
    default: "#fff",
  },
});
function getRandomColor() {
  // const letters = "0123456789ABCDEF";
  const letters = "3456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 13)];
  }
  return color;
}
let backgroundColor = ref(null);
let showName = ref("");
onMounted(() => {
  if (props.color) {
    backgroundColor.value = props.color;
  } else {
    backgroundColor.value = getRandomColor();
  }
  let nameStr = props.init.name;
  if (!nameStr) return;
  let nameStrLength = props.init?.name?.length;
  if (nameStrLength > 2) {
    showName.value = nameStr.substring(nameStrLength - 2, nameStrLength);
  } else {
    showName.value = nameStr;
  }
});
</script>
<style scoped>
.avtar {
  width: 44px;
  text-align: center;
  height: 44px;
  line-height: 44px;
  border-radius: 50%;
  color: #fff;
  font-weight: 700;
  container-type: inline-size;
}
/* .avtar span {
  font-size: 25cqw;
  display: inline-block;
  text-align: center;
  width: 100%;
} */
</style>
