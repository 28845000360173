<template>
  <el-dialog
    title="管理合作伙伴"
    top="5%"
    :close-on-click-modal="false"
    v-model="managerDialog"
    draggable
    modal-class="overflow-hidden "
    width="520px"
    class="mhg-dialog-pd radius12"
    :append-to-body="true"
  >
    <el-form label-width="80px" ref="ruleFormRef">
      <el-form-item label="名称" required>
        <el-input v-model="customer.name" placeholder="请填写合作伙伴的名称"></el-input>
      </el-form-item>
      <el-form-item label="类型">
        <el-select v-model="customer.types" multiple placeholder="选择类型" class="w-[100%]">
          <el-option v-for="item in ['客户', '加工厂', '供应商']" :key="item" :label="item" :value="item" />
        </el-select>
      </el-form-item>
      <el-form-item label="标签">
        <el-select
          class="w-[100%]"
          v-model="customer.tags"
          multiple
          filterable
          allow-create
          default-first-option
          :reserve-keyword="false"
          placeholder="选择标签（输入回车可直接添加标签）"
        >
          <el-option v-for="item in tagsOptions" :key="item" :label="item" :value="item" />
        </el-select>
      </el-form-item>
      <el-form-item label="手机号">
        <el-input v-model="customer.phone" placeholder="手机号" />
      </el-form-item>
      <el-form-item label="地址">
        <mhg-address @change="getAddr" :initData="customer.address" />
      </el-form-item>

      <el-form-item label="备注">
        <el-input v-model="customer.remark" type="textarea" placeholder="备注" />
      </el-form-item>
    </el-form>
    <div class="text-right mt-3.5">
      <el-button @click="closeDialog">取消</el-button>
      <el-button type="primary" @click="confirmSave">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script setup lang="ts">
import { ref, reactive, onMounted, getCurrentInstance } from "vue";
import { message } from "@/utils/message";
import mhgAddress from "@/components/mhgAddress.vue";
import { isEmpty, uniq } from "lodash-es";
import { searchFactory, bindFactory, unbindFactory, setConfig, getConfig } from "@/api/factory";
import { getPartnerList, getPartnerCount, delPartner, savePartner, accept, exportList } from "@/api/customer";
const props = defineProps({
  from: {
    type: String,
    default: "",
  },
});
let customer = ref({
  id: null,
  types: [],
  tags: [],
  name: null,
  phone: null,
  address: {
    province: '',
    city: '',
    street: '',
    area: '',
  },
  remark: null,
});
const emits = defineEmits(["close", "change"]);
function closeDialog() {
  // managerDialog.value = false;
  emits("close");
}
//获取地址
function getAddr(res) {
  customer.value.address.province = res.data.province;
  customer.value.address.city = res.data.city;
  customer.value.address.street = res.data.street;
  customer.value.address.area = res.data.area;
}
let managerDialog = ref(false);
let tagsOptions: any = ref([]);
function confirmSave() {
  if (!customer.value.name) {
    return message("请填写客户名称");
  }
  console.log(tagsOptions.value);
  tagsOptions.value = tagsOptions.value.concat(customer.value.tags);
  tagsOptions.value = uniq(tagsOptions.value);
  savePartner(customer.value).then((res) => {
    if (res) {
      message("操作成功!", { type: "success" });
      managerDialog.value = false;
      onSetConfig(tagsOptions.value); //保存tags
      emits("change");
    }
  });
}
//获取标签
function onGetConfig() {
  getConfig()
    .then((res: any) => {
      if (res) {
        tagsOptions.value = res.data && res.data.customer_tag ? res.data.customer_tag : [];
      }
    })
    .catch(() => {});
}
//保存标签
function onSetConfig(data) {
  setConfig({ customer_tag: data }).then((res) => {
    if (res) {
      onGetConfig();
    }
  });
}
onMounted(() => {
  managerDialog.value = true;
  onGetConfig();
});
</script>
<style scoped></style>
