import clothPurchaseSvg from "@/assets/svg/clothPurchase.svg";
import { getRank } from '../rank'
let rank = getRank('clothPurchase')
export default {
  path: "/clothPurchase",
  redirect: "/clothPurchase/inventory",
  name: "clothPurchase_index",
  meta: {
    icon: clothPurchaseSvg,
    title: "布料进销存",
    rank,
    roles: ['cloth_router']
  },
  children: [
    {
      path: "/clothPurchase/clothManager",
      name: "ClothManager",
      component: () => import("@/views/CRM/clothPurchase/clothManager/index.vue"),
      meta: {
        title: "布料管理",
        keepAlive: false,
        roles:['cloth_view']
      }
    },
    {
      path: "/clothPurchase/inventory",
      name: "ClothInventory",
      component: () => import("@/views/CRM/clothPurchase/inventory/index.vue"),
      meta: {
        title: "仓库库存",
        keepAlive: false,
        roles:['cloth_inventory_view']
      }
    },
    {
      path: "/clothPurchase/invoice",
      name: "clothInvoice",
      component: () => import("@/views/CRM/clothPurchase/inAndOutStorge/index.vue"),
      meta: {
        title: "出入库单",
        keepAlive: false,
        roles:['cloth_inventory_movement_view']
      }
    },
    {
      path: "/clothPurchase/warning",
      name: "ClothWarning",
      component: () => import("@/views/CRM/clothPurchase/warning/index.vue"),
      meta: {
        title: "库存预警",
        keepAlive: false,
        roles:['cloth_inventory_alert_view']
      }
    },
    {
      path: "/clothPurchase/sell",
      name: "ClothSell",
      component: () => import("@/views/CRM/clothPurchase/sell/index.vue"),
      meta: {
        title: "销售",
        keepAlive: false,
        roles:['cloth_sell_out_view']
      }
    },
    {
      path: "/clothPurchase/statement",
      name: "ClothStatement",
      component: () => import("@/views/CRM/clothPurchase/statement/index.vue"),
      meta: {
        title: "报表",
        keepAlive: false,
        roles:['cloth_sell_report_view']
      }
    },
  ]
} as RouteConfigsTable;
