<template>
  <div>
    <el-form ref="form" label-width="80px">
      <el-form-item>
        <template #label>
          <span>品牌</span>
        </template>
        <span>棉花果云打印</span>
      </el-form-item>
      <el-form-item prop="name">
        <template #label>
          <div>
            <span class="mhg-required">名称</span>
            <el-popover placement="top-start" title="名称" width="200" trigger="hover" content="云打印机名称可任意填写">
              <template #reference>
                <el-icon class="color999">
                  <QuestionFilled />
                </el-icon>
              </template>
            </el-popover>
          </div>
        </template>
        <el-input v-model="device.name" />
      </el-form-item>
      <el-form-item label="IMEI">
        <template #label>
          <div>
            <span class="mhg-required">IMEI</span>
            <el-popover placement="top-start" title="如何查看IMEI" width="200" trigger="hover" content="云打印底部标有IMEI信息">
              <template #reference>
                <el-icon class="color999">
                  <QuestionFilled />
                </el-icon>
              </template>
            </el-popover>
          </div>
        </template>
        <el-input v-model="device.device_info.printer_id" />
      </el-form-item>
      <el-form-item label="SN码">
        <template #label>
          <div>
            <span class="mhg-required">SN码</span>
            <el-popover placement="top-start" title="如何查看SN码" width="200" trigger="hover" content="云打印底部标有SN码信息">
              <template #reference>
                <el-icon class="color999">
                  <QuestionFilled />
                </el-icon>
              </template>
            </el-popover>
          </div>
        </template>
        <el-input v-model="device.device_info.sn" />
      </el-form-item>
      <el-form-item label="验证码">
        <template #label>
          <div>
            <span class="mhg-required">验证码</span>
            <el-popover
              placement="top-start"
              title="如何查看验证码"
              width="200"
              trigger="hover"
              content="点击【发送验证码】后，系统会向云打印机发送打印验证码指令，请确保云打印机当前为开机状态"
            >
              <template #reference>
                <el-icon class="color999">
                  <QuestionFilled />
                </el-icon>
              </template>
            </el-popover>
          </div>
        </template>
        <el-input class="radius-left-12" placeholder="请输入验证码" v-model="device.verify_code">
          <template #append>
            <el-button style="padding: 1px 20px" @click="verifyCode">发送验证码</el-button>
          </template>
        </el-input>
      </el-form-item>
    </el-form>
    <div class="!text-right mt-[30px]">
      <el-button @click="close">取消</el-button>
      <el-button @click="bindDevice" type="primary" plain>绑定打印机</el-button>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref, reactive, onMounted, getCurrentInstance } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import { message } from "@/utils/message";
import { isEmpty, filter } from "lodash-es";
import { saveCloudPrint, sendVerifyCode, bindPrinter } from "@/api/cloudPrint";
import { cainiao } from "@/utils/common-node-service.js";
import { useUserInfo } from "@/store/modules/useInfo";
const user = useUserInfo();
const userInfo = user.getUserInfo();
const { proxy } = getCurrentInstance();
const device = ref({
  id: null,
  name: "",
  type: "cainiao",
  device_id: null,
  device_info: {
    printer_id: "",
    share_code: "",
    sn: "",
  },
  verify_code: "",
});
function verifyCode() {
  if (!device.value.device_info.sn) {
    return message("SN号不可为空");
  }
  if (!device.value.device_info.printer_id) {
    return message("IMEI号不可为空");
  }
  cainiao
    .sendVerifyCode({ printer_id: device.value.device_info.printer_id })
    .then((res) => {
      if (res) {
        message("发送验证码成功，请到打印机查看", { type: "success" });
      }
    })
    .catch((err) => {
      message(err || "打印失败");
    });
}
function close() {
  sendInfo("close");
}
//绑定打印机
function bindDevice() {
  const { name, device_info, verify_code } = device.value;
  if (!name) return message("打印机名称不可为空");
  if (!device_info.printer_id) return message("IMEI号不可为空");
  if (!device_info.sn) return message("SN码不可为空");
  if (!verify_code) return message("验证码不可为空");
  cainiao
    .bindPrinter({ printer_id: device_info.printer_id, verify_code })
    .then((res: any) => {
      if (res) {
        device.value.device_info.share_code = res.data?.share_code;
        device.value.device_id = device_info?.printer_id || null;
        onSaveCloudPrint(device.value);
        device.value = {
          id: null,
          name: "",
          type: "cainiao",
          device_id: null,
          device_info: {
            printer_id: "",
            share_code: "",
            sn: "",
          },
          verify_code: "",
        };
      }
    })
    .catch((err) => {
      message(err || "绑定失败");
    });
}
function onSaveCloudPrint(data) {
  saveCloudPrint(data).then((res) => {
    if (res) {
      message("绑定成功", { type: "success" });
      sendInfo("success");
    }
  });
}
//传递消息给父组件
const emits = defineEmits(["change"]);
function sendInfo(type) {
  emits("change", { type });
}
</script>

<style scoped></style>
