import mhgFormInput from './mhgFormInput.vue'
import myScroll from './myScroll.vue'
import mhgFormDes from './mhgFormDes.vue'
import rackLocation from './rackLocation.vue'
import selectPartner from './selectPartner.vue'
import selectOrderOrStyle from './select/selectOrderOrStyle.vue'
import selectOrderOrStyleMachine from './select/selectOrderOrStyleMachine.vue'
import printSelect from './select/printSelect.vue'
import searchForType from './search/searchForType.vue'
import mhgTimeSearch from './mhgTimeSearch.vue'
export const gCom = (app: any) => {
  app.component('mhgFormInput', mhgFormInput)
  app.component('myScroll', myScroll)
  app.component('mhgFormDes', mhgFormDes)
  app.component('rackLocation', rackLocation)
  app.component('selectPartner', selectPartner)
  app.component('selectOrderOrStyle', selectOrderOrStyle)
  app.component('selectOrderOrStyleMachine', selectOrderOrStyleMachine)
  app.component('printSelect', printSelect)
  app.component('searchForType', searchForType)
  app.component('mhgTimeSearch', mhgTimeSearch)
}
