<template>
  <el-popover :visible="visible" placement="bottom" :width="1000">
    <template #reference>
      <div
        class="mhg-select-div no-wrap p-[0 0.25rem] !w-[200px]"
        @click="visible = !visible"
        :style="{
          border: visible ? '1px solid #ff6600' : '',
        }"
      >
        <div class="overflow-hidden">
          <span v-if="curTemplate" class="mr-1 ml-2 flex items-center" style="line-height: 32px">
            {{ curTemplate.name }}
          </span>
        </div>
        <el-icon class="mhg-select-div-icon">
          <ArrowDown />
        </el-icon>
      </div>
    </template>
    <template #default>
      <div class="relative">
        <div class="overflow-hidden text-right flex items-center justify-end">
          <router-link to="/kun_tie/index" target="_blank">
            <el-button class="mr-[30px]">打印中心</el-button>
          </router-link>
          <!-- <el-button class="mr-[30px]" @click="onSignKunTie">打印中心</el-button> -->
          <el-icon @click="visible = false" class="close-popover hover-theme"><CircleClose /></el-icon>
        </div>

        <div class="mt-[10px]">
          <el-form class="mb0" ref="form" label-position="right" label-width="70px" :inline="true">
            <el-form-item label="来源">
              <el-select v-model="printCenterType" class="!w-[200px]" placeholder="选择标签来源" @change="printCenterTypeChange">
                <el-option
                  v-for="item in [
                    { label: '我的', id: 'my' },
                    { label: '云分享', id: 'cloud' },
                    { label: '系统推荐', id: 'sys' },
                  ]"
                  :label="item.label"
                  :value="item.id"
                  :key="item.id"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="标签名称">
              <el-input
                v-model="serachLabelData.keyWord"
                class="ml-1 !w-[200px]"
                @input="searchLabel"
                placeholder="请输入标签名称"
                clearable
                @clear="searchLabel"
                :suffix-icon="Search"
              />
            </el-form-item>
            <el-form-item label="分类">
              <el-select @change="searchLabel" v-model="serachLabelData.category_id" clearable filterable default-first-option placeholder="请选择" class="!w-[250px]">
                <el-option v-for="item in categoryOption" :key="item.id" :label="item.name" :value="item.id" />
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <div class="max-h-[500px] overflow-auto mt-3">
          <div class="flex items-center flex-wrap">
            <el-card shadow="hover" v-for="(item, index) in labelList" class="img-div-item pd0 !mb-3" @click="selctTemplate(item)" :body-style="{ padding: '0px' }">
              <div class="label-main">
                <!-- <img :src="$getStaticImage('/src/assets/print_images/', item.thumbnail, printCenterType)" class="image" /> -->
                <img :src="item.thumbnail" alt="" class="card-img" />
              </div>
              <div class="label-name">
                {{ item.name }}
              </div>
            </el-card>
          </div>
          <el-empty v-if="!labelList.length"></el-empty>
        </div>
        <div class="text-right mt-[50px]">
          <div class="mt-3 pb-9 overflow-hidden">
            <el-pagination
              class="fr"
              v-model:current-page="serachLabelData.page_index"
              :page-size="serachLabelData.page_size"
              :page-sizes="[8, 10, 20, 40, 80, 100, 200]"
              layout="total, sizes, prev, pager, next, jumper"
              :total="pageCount"
              @size-change="onSizeChange"
              @current-change="onPageChange"
            />
          </div>
          <div class="text-red fr" v-if="printCenterType == 'web'">选择网页模板，需在网页打印中重新选择打印机</div>
        </div>
      </div>
    </template>
  </el-popover>
</template>

<script lang="ts" setup>
import { Search } from "@element-plus/icons-vue";
import { ref, reactive, onMounted, getCurrentInstance } from "vue";
import { cainiao } from "@/utils/common-node-service";
import { isEmpty } from "lodash-es";
const { proxy } = getCurrentInstance();
import { getLabelDetail, getKuntieCategory, getLabels, signKunTie } from "@/api/kunTie";
import router from "@/router";
const props = defineProps({
  storeStr: {
    //浏览器缓存的打印模板类型，用于在各个打印地方的类型区分
    type: String,
    default: "curPrintObj",
  },
  category_id: {
    type: Number,
    default: null,
  },
});
const visible = ref(false);
/**********************选择标签样式相关********************** */
// let labelData = reactive({
//   list: [], //  标签数据
//   total: 0, //  总条目数
//   category: [], //  标签分类
//   selectedLabel: null, //   选中的标签
//   labelType: null, //选中的标签样式，来自打印中心center还是网页模板web
// });
let printCenterType = ref("sys");
let serachLabelData = ref({
  keyword: null,
  page_index: 1,
  page_size: 10,
  is_cloud: null,
  is_public: null,
  category_id: null,
  sort: null,
  keyWord: null,
});
function printCenterTypeChange(type) {
  labelList.value = [];
  switch (type) {
    case "my":
      serachLabelData.value.is_public = false;
      serachLabelData.value.is_cloud = false;
      onGetLabels(serachLabelData.value);
      break;
    case "cloud":
      serachLabelData.value.is_public = true;
      serachLabelData.value.is_cloud = false;
      onGetLabels(serachLabelData.value);
      break;
    case "sys":
      serachLabelData.value.is_cloud = true;
      serachLabelData.value.is_public = false;
      onGetLabels(serachLabelData.value);
      break;
    // case "web":
    //   getWebLabel();
    //   break;
    // case "4G":
    //   onGetPrinterTemplates();
    //   break;
  }
}
function onSizeChange(size) {
  serachLabelData.value.page_size = size;
  serachLabelData.value.page_index = 1;
  onGetLabels(serachLabelData.value);
}
function onPageChange(page) {
  serachLabelData.value.page_index = page;
  onGetLabels(serachLabelData.value);
}
function searchLabel() {
  onGetLabels(serachLabelData.value);
  // if (printCenterType.value == "4G" || printCenterType.value == "web") {
  //   labelList.value = labelList_bak.value.filter((item) => {
  //     return item.name.indexOf(serachLabelData.value.keyWord) != -1;
  //   });
  // } else {
  //   onGetLabels(serachLabelData.value);
  // }
}
let labelList = ref([]);
// let labelList_bak = ref([]);
// function getWebLabel() {
//   let url = import.meta.env.VITE_PUBLIC_PATH + "print/printType.json";
//   fetch(url)
//     .then((response) => response.json())
//     .then((res) => {
//       labelList.value = res;
//       labelList_bak.value = res;
//     });
// }
//获取模板
// function onGetPrinterTemplates() {
//   cainiao.getTemplates().then((res) => {
//     if (res) {
//       labelList.value = res.templates;
//       labelList_bak.value = res.templates;
//     }
//   });
// }
/**********************选择标签样式相关********************** */

//选择模板
let curTemplate = ref(null);
function selctTemplate(obj) {
  curTemplate.value = obj;
  //  保存到本地
  proxy.$mhgSetItem(props.storeStr, { printCenterType: printCenterType.value, template: obj });
  visible.value = false;
  sendInfo();
  if (obj.id) {
    onGetLabelDetail({ id: obj.id });
  }
}
let labelDetail = ref();
function onGetLabelDetail(data) {
  getLabelDetail(data).then((res) => {
    if (res) {
      labelDetail.value = res.data;
      emits("detailChange", { detail: labelDetail.value });
    }
  });
}
let categoryOption = ref([]);
function onGetKuntieCategory() {
  getKuntieCategory().then((res) => {
    if (res) {
      categoryOption.value = res.data;
    }
  });
}
let pageCount = ref(0);
function onGetLabels(data) {
  getLabels(data).then((res: any) => {
    if (res) {
      labelList.value = res.data.data;
      labelList.value = labelList.value.map((item) => {
        // item.imageUrl = item.thumbnail;
        item.thumbnail = item.thumbnail + "?" + new Date().getTime();
        return item;
      });
      pageCount.value = res.data?.pagination?.total_page;
    }
  });
}
let baseUrl = ref("");
function onSignKunTie() {
  baseUrl.value = import.meta.env.VITE_API_URL;
  window.open(`${baseUrl.value}#/kun_tie/index`);
}
//传递消息给父组件
const emits = defineEmits(["change", "detailChange"]);
function sendInfo() {
  emits("change", { data: curTemplate.value, from: printCenterType.value });
}
onMounted(() => {
  serachLabelData.value.category_id = props.category_id;
  //  从本地获取
  let curPrintObj = proxy.$mhgGetItem(props.storeStr);
  if (curPrintObj) {
    curTemplate.value = curPrintObj.template;
    printCenterType.value = curPrintObj.printCenterType;
    if (curTemplate?.value?.id) {
      onGetLabelDetail({ id: curTemplate.value.id });
    }
    window.addEventListener("message", (event) => {
      if (event.data === "kuntie_closed") {
        console.log("==================kuntie_closed=================");
        printCenterTypeChange(printCenterType.value);
      }
    });
  }

  sendInfo();
  printCenterTypeChange(printCenterType.value);
  onGetKuntieCategory();
});
</script>

<style lang="scss" scoped>
.mhg-select-div {
  display: inline-block;
  padding: 0 20px 0 5px;
}

.label-list {
  height: 400px;
  overflow: auto;
}

.label-main {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.img-div-item.active .mark-icon i {
  color: #ff6600 !important;
}

.mark-icon {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 20px;
  border: 1px solid #ccc;
  border-radius: 20px;
  height: 15px;
  width: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.label-name {
  background: #808080;
  color: #fff;
  padding: 10px;
  position: absolute;
  width: 100%;
  bottom: 0;
}

.img-div {
}

.img-div-item {
  border: 1px solid #eee;
  margin: 0 15px;
  position: relative;
  height: 310px;
  flex: 0 0 200px;
  border-radius: 12px;
}

.img-div-item.active {
  border: 1px solid #ff6600 !important;
  box-shadow: 0 2px 12px 0 rgb(255 102 0 / 10%);
}

.img-div-item img {
  max-height: 233px;
}

.img-div-item:hover {
  cursor: pointer;
}

.label-line-height .el-form-item__label {
  line-height: unset !important;
}

.el-form-item {
  margin-bottom: 10px;
}

.el-dropdown-menu__item i {
  margin-right: 0px;
}

.parameter thead tr th,
.parameter tbody tr td {
  padding-right: 15px;
  padding-bottom: 10px;
}

.w219 {
  width: 219px;
}

.select-print {
  position: absolute;
}
.close-popover {
  // position: absolute;
  right: 0;
  font-size: 20px;
}

.tag:hover {
  cursor: pointer;
}
</style>
