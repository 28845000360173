import { http } from "@/utils/http";
import {
  ResultCount,
  ResultList,
  ResultObject,
  ResultStr,
  ResultObjectStyle
} from "./types.d";
export type UserResult = {
  success: boolean;
  data: {
    /** 用户名 */
    username: string;
    /** 当前登陆用户的角色 */
    roles: Array<string>;
    /** `token` */
    accessToken: string;
    /** 用于调用刷新`accessToken`的接口时所需的`token` */
    refreshToken: string;
    /** `accessToken`的过期时间（格式'xxxx/xx/xx xx:xx:xx'） */
    expires: Date;
  };
};

export type RefreshTokenResult = {
  success: boolean;
  data: {
    /** `token` */
    accessToken: string;
    /** 用于调用刷新`accessToken`的接口时所需的`token` */
    refreshToken: string;
    /** `accessToken`的过期时间（格式'xxxx/xx/xx xx:xx:xx'） */
    expires: Date;
  };
};

/** 登录 */
export const getLogin = (data?: object) => {
  return http.request<UserResult>("post", "/auth/login", { data });
};

/** 刷新token */
export const refreshTokenApi = (data?: object) => {
  return http.request<RefreshTokenResult>("post", "/refreshToken", { data });
};

/** 设置自定义属性  保存历史数据统一按厂为单位*/
export const setCustom = (data?: object) => {
  return http.request<ResultObject>("post", "/main/factory/setConfig", { data });
};
/** 获取自定义属性 */
export const getCustom = (data?: object) => {
  return http.request<ResultObject>("post", "/main/factory/getConfig", { data });
};
//更新员工信息
export const getUserInfo = (data?: object) => {
  return http.request<ResultObject>("post", "/main/user/info", { data });
};
//信息
export const fullInfo = (data?: object) => {
  return http.request<ResultObject>("post", "/main/user/fullInfo", { data });
};
//更新员工信息
export const userUpdate = (data?: object) => {
  return http.request<ResultObject>("post", "/main/user/update", { data });
};
//获取会员价格 
export const getMemberPrice = (data?: object) => {
  return http.request<ResultObject>("post", "/main/member/getMemberPrice", { data });
};
//计算会员价格 
export const calMemberPrice = (data?: object) => {
  return http.request<ResultObject>("post", "/main/member/calMemberPrice", { data });
};
//创建支付订单 
export const createMemberOrder = (data?: object) => {
  return http.request<ResultObject>("post", "/main/member/createMemberOrder", { data });
};
//同步棉花果数据
export const createSyncJob = (data?: object) => {
  return http.request<ResultObject>("post", "/main/factory/createSyncJob", { data });
};
