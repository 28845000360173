import { http } from "@/utils/http";
import {
  ResultCount,
  ResultList,
  ResultObject,
  ResultStr,
  ResultObjectStyle
} from "./types.d";
/**获取客户列表 */
export const getPartnerList = (data?: object) => {
  return http.request<ResultList>("post", "/main/partner/getList", { data }, null, false, ['partner_view', 'order', 'style', 'accessory', 'cloth', 'product', 'design_style']);

  // return http.request<ResultList>("post", "/main/staff/getApplyList", { data });
};
/**获取客户列表总数 */
export const getPartnerCount = (data?: object) => {
  return http.request<ResultCount>("post", "/main/partner/getCount", { data }, null, false, ['partner_view', 'order', 'style', 'accessory', 'cloth', 'product', 'design_style']);
  // return http.request<ResultCount>("post", "/main/factory/getConfig", { data });
};
/**获取客户列表总数 */
export const getPartnerDetail = (data?: object) => {
  return http.request<ResultCount>("post", "/main/partner/get", { data }, null, false, ['partner_view']);
};
/**根据名称创建客户 */
export const createPartnerByName = (data?: object) => {
  return http.request<ResultObject>("post", "/main/partner/createByName", {
    data
  });
};
/**创建 */
export const savePartner = (data?: object) => {
  return http.request<ResultObject>("post", "/main/partner/save", { data });
};
/**删除 */
export const delPartner = (data?: object) => {
  return http.request<ResultObject>("post", "/main/partner/delete", { data });
};
/**同意添加合作伙伴 */
export const accept = (data?: object) => {
  return http.request<ResultObject>("post", "/main/partner/accept", { data });
};

//导出
export const exportList = (data?: object) => {
  return http.request<ResultStr>("post", "/main/partner/exportList", { data });
};
/**搜素工厂 */
export const searchFactory = (data?: object) => {
  return http.request<ResultList>("post", "/main/partner/searchFactory", { data }, null, false, ['partner_manager']);
};
