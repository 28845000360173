import { http } from "@/utils/http";
import {
  ResultCount,
  ResultList,
  ResultObject,
  ResultStr,
  ResultObjectStyle
} from "./types.d";

//获取config
export const getConfig = (data?: object) => {
  return http.request<ResultObject>("post", "/main/factory/getConfig", {
    data
  });
};
//设置config
export const setConfig = (data?: object) => {
  return http.request<ResultObject>("post", "/main/factory/setConfig", {
    data
  });
};
//当前工厂信息
export const factoryInfo = (data?: object) => {
  return http.request<ResultObject>("post", "/main/factory/info", { data });
};
//更新工厂信息
export const factoryUpdate = (data?: object) => {
  return http.request<ResultObject>("post", "/main/factory/update", { data });
};
//切换工厂
export const choiceFactory = (data?: object) => {
  return http.request<ResultObject>("post", "/main/user/choiceFactory", {
    data
  });
};
//搜索工厂
export const searchFactory = (data?: object) => {
  return http.request<ResultList>("post", "/main/factory/search", { data });
};

//申请进入工厂
export const applyInFactory = (data?: object) => {
  return http.request<ResultObject>("post", "/main/factory/applyFor", { data });
};
//获取加入的工厂 
export const joined = (data: object) => {
  return http.request<ResultList>("post", "/main/factory/joined", { data });
};
//绑定工厂 
export const bindFactory = (data: object) => {
  return http.request<ResultObject>("post", "/main/partner/bindFactory", { data });
};
//解除绑定 
export const unbindFactory = (data: object) => {
  return http.request<ResultObject>("post", "/main/partner/unbindFactory", { data });
};