<template>
  <div>
    <el-form label-width="90px" ref="form" label-position="right">
      <el-form-item label="标签样式：">
        <print-template-select @detailChange="getPrintTemplate" :store-str="storeStr" :category_id="category_id"></print-template-select>
      </el-form-item>
      <el-form-item label="打印机：">
        <cloud-printer-select @change="getCloudPrinter" width="200px"></cloud-printer-select>
      </el-form-item>
    </el-form>
    <!--标签预览-->
    <div class="mt-[40px]" v-if="curPrintTemplate">
      <div class="text-[#333]">标签预览</div>
      <div class="mt-6 text-center">
        <el-image
          v-if="curPrintTemplate.thumbnail"
          style="max-width: 180px; border: 1px solid #d1d1d1; border-radius: 12px"
          :key="curPrintTemplate.thumbnail"
          class="bg-w"
          :src="curPrintTemplate.thumbnail"
          :preview-src-list="[curPrintTemplate.thumbnail]"
        >
        </el-image>
      </div>
      <div class="mt-3">标签大小：{{ curPrintTemplate.width || 40 }}mm*{{ curPrintTemplate.height || 60 }}mm</div>
      <div class="mt-1">标签名称：{{ curPrintTemplate.name }}</div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, reactive, onMounted, getCurrentInstance } from "vue";
import { message } from "@/utils/message";
import { isEmpty } from "lodash-es";
import printTemplateSelect from "@/components/PrintTicket/printTemplateSelect.vue";
import cloudPrinterSelect from "@/components/cloudPrinterSelect.vue";
const props = defineProps({
  from: {
    type: String,
    default: "",
  },
  storeStr: {
    type: String,
    default: "curPrintObj",
  },
  category_id: {
    type: Number,
    default: null,
  },
  modelValue: {
    type: Object,
    default: {
      label_id: null,
      printer_id: null,
    },
  },
});
const emits = defineEmits(["change", "update:modelValue"]);
let printData = ref({
  label_id: null,
  printer_id: null,
});
//获取云打印机
let curPrintDeviceObj = ref(null);
function getCloudPrinter(res) {
  curPrintDeviceObj.value = res.data;
  printData.value.printer_id = res?.data?.id || null;
  emits("update:modelValue", printData.value);
}
//获取打印模板详细
let curPrintTemplate = ref(null);
function getPrintTemplate(res) {
  curPrintTemplate.value = res?.detail || null;
  printData.value.label_id = res?.detail?.id || null;
  emits("update:modelValue", printData.value);
  emits("change", res?.detail);
}

onMounted(() => {});
</script>
<style scoped></style>
