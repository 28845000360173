import baseSvg from "@/assets/svg/base.svg";
import { getRank } from '../rank'
let rank = getRank('category')
export default {
  path: "/category",
  name: "category_index",
  meta: {
    icon: baseSvg,
    title: "款式资料",
    rank,
    roles: ['production_router']
  },
  children: [
    {
      path: "/category/index",
      name: "category",
      component: () => import("@/views/category/index.vue"),
      meta: {
        title: "款式资料",
        keepAlive: false,
        roles: ['style_view']
      }
    },
    {
      path: "/category/categoryManager",
      name: "categoryManager",
      component: () => import("@/views/category/categoryManager.vue"),
      meta: {
        title: "添加/修改商品",
        keepAlive: false,
        showLink: false
      }
    },
    {
      path: "/category/categoryDetail",
      name: "categoryDetail",
      component: () => import("@/views/category/categoryDetail.vue"),
      meta: {
        title: "商品",
        keepAlive: false,
        showLink: false
      }
    },

  ]
} as RouteConfigsTable;
