import { http } from "@/utils/http";

/** 获取列表 */
export type listResult = {
  code: number;
  data?: [];
  message: string;
  pageItem?: number;
};
/** 获取列表 */
export type objResult = {
  code: number;
  data?: any;
  message: string;
  pageItem?: number;
};
/** 获取分页 */
export type pageResult = {
  code: number;
  data: number;
  message: string;
  pageItem?: number;
};
//款式列表
export const getCategorys = (data: object) => {
  return http.request<listResult>("post", "/main/style/getList", { data }, null, false, ['style_view', 'design_style_view']);
};
//获取款式总数
export const getCategoryCount = (data: object) => {
  return http.request<pageResult>("post", "/main/style/getCount", { data }, null, false, ['style_view', 'design_style_view']);
};
//款式详细
export const categoryDetail = (data: object) => {
  return http.request<objResult>("post", "/main/style/get", { data }, null, false, ['style_view', 'design_style_view']);
};
//保存款式
export const saveCategory = (data: object) => {
  return http.request<objResult>("post", "/main/style/save", { data });
};
//删除款式
export const delCategory = (data: object) => {
  return http.request<objResult>("post", "/main/style/delete", { data });
};
//款式同步工序
export const syncCategory = (data: object) => {
  return http.request<objResult>("post", "/main/step/syncStyle", { data });
};
//搜索工序
export const searchSteps = (data: object) => {
  return http.request<listResult>("post", "/main/step-library/getList", { data }, null, false, ['step_view', 'step_fixed_manager', 'style_step_mod', 'order_step_mod']);
};
// 获取样板分类列表
export const getStyleClassList = (data: object) => {
  return http.request<listResult>("post", "/main/style-class/getList", { data });
};
// 保存样板分类
export const saveStyleClass = (data: object) => {
  return http.request<objResult>("post", "/main/style-class/save", { data });
};
// 删除样板分类
export const delStyleClass = (data: object) => {
  return http.request<objResult>("post", "/main/style-class/delete", { data });
};
// 生成款式预算成本
export const generateBudgetCost = (data: object) => {
  return http.request<objResult>("post", "/main/style/generateBudgetCost", { data }, null, false, ['style_add', 'style_mod', 'style_view']);
};
// 生成款式商品
export const generateProducts = (data: object) => {
  return http.request<objResult>("post", "/main/style/generateProducts", { data });
};
//获取服装类目
export const getCategoryTree = (data?: object) => {
  return http.request<listResult>("post", "/main/clothing-category/tree", { data });
};
//获取服装类目包含统计
export const getClothCategorySummary = (data?: object) => {
  return http.request<listResult>("post", "/main/clothing-category/summary", { data });
};

//获取存在该款的仓库
export const getWarehouseHaveStyle = (data?: object) => {
  return http.request<listResult>("post", "/main/style/getWarehouse", { data }, null, false, ['style_add', 'style_mod', 'product_inventory_price_mod']);
};
//同步销售价格到库存
export const syncPricesToInventory = (data?: object) => {
  return http.request<listResult>("post", "/main/style/syncPricesToInventory", { data });
};
