import { http } from "@/utils/http";
import {
  ResultCount,
  ResultList,
  ResultObject,
  ResultStr,
  ResultObjectStyle
} from "./types.d";

export const getOrderList = (data?: object, loading?: Boolean) => {
  return http.request<ResultList>(
    "post",
    "/main/order/getList",
    { data },
    null,
    loading
  );
};
export const getOrdersCount = (data?: object) => {
  return http.request<ResultCount>("post", "/main/order/getCount", { data }, null, false, ['order_view']);
};

/**计算菲票规则 */
export const calRule = (data?: object) => {
  return http.request<ResultList>("post", "/main/ticket/calRule", { data });
};

//菲票进度
export const getTicketsProgress = (data?: object) => {
  return http.request<ResultList>("post", "/main/ticket/getTicketsProgress", { data });
};
//导出菲票进度
export const exportTicketsProgress = (data?: object) => {
  return http.request<ResultStr>("post", "/main/ticket/exportTicketsProgress", { data });
};


//生成款式编号
export const createStyleNo = (data?: object) => {
  return http.request<ResultStr>("post", "/main/order/createStyleNo", { data });
};
//生成订单编号
export const createOrderNo = (data?: object) => {
  return http.request<ResultStr>("post", "/main/order/createOrderNo", { data });
};
/**生成床次编号 */
export const createMachineNo = (data?: object) => {
  return http.request<ResultStr>("post", "/main/order/createMachineNo", {
    data
  });
};
/**创建订单 */
export const createOrder = (data?: object) => {
  return http.request<ResultStr>("post", "/main/order/create", { data });
};
//删除订单
export const delOrder = (data?: object) => {
  return http.request<ResultStr>("post", "/main/order/delete", { data });
};
//批量删除订单
export const deleteMultiOrder = (data?: object) => {
  return http.request<ResultStr>("post", "/main/order/deleteMulti", { data });
};
//订单详细
export const orderDetail = (data?: object) => {
  return http.request<ResultObjectStyle>("post", "/main/order/get", { data }, null, false, ['work', 'order_view']);
};
//获取菲票列表
export const ticketList = (data?: object) => {
  return http.request<ResultObjectStyle>("post", "/main/ticket/getList", {
    data
  });
};
//获取菲票列表
export const ticketModify = (data?: object) => {
  return http.request<ResultStr>("post", "/main/ticket/modify", { data });
};
//子床次编菲 /main/order/buildMachine
export const buildMachine = (data?: object) => {
  return http.request<ResultObject>("post", "/main/order/buildMachine", {
    data
  });
};
//更新订单
export const orderUpdate = (data?: object) => {
  return http.request<ResultStr>("post", "/main/order/update", { data });
};
//工序员工完成明细
export const getStepUsersRecord = (data?: object) => {
  return http.request<ResultList>("post", "/main/order/getStepUsersRecord", {
    data
  }, null, false, ['work', 'order_view']);
};
//获取菲票列表
export const getTickets = (data?: object) => {
  return http.request<ResultList>("post", "/main/ticket/getList", {
    data
  });
};
//获取工作菲票列表
export const getWorkTickets = (data?: object) => {
  return http.request<ResultList>("post", "/main/ticket/getWorks", {
    data
  });
};
//删除订单床次
export const deleteMachine = (data?: object) => {
  return http.request<ResultStr>("post", "/main/order/deleteMachine", {
    data
  });
};
//增加菲票
export const addTicket = (data?: object) => {
  return http.request<ResultStr>("post", "/main/ticket/add", {
    data
  });
};
//删除菲票
export const deleteTicket = (data?: object) => {
  return http.request<ResultStr>("post", "/main/ticket/delete", {
    data
  });
};
//保存订单工序
export const saveOrderSteps = (data?: object) => {
  return http.request<ResultStr>("post", "/main/step/saveOrder", {
    data
  });
};


//********************外发-new********************** */
//创建外发单
export const createOutsource = (data?: object) => {
  return http.request<ResultStr>("post", "/main/outsource/create", {
    data
  });
};

//获取外发单列表
export const getSendOrderList = (data?: object) => {
  return http.request<ResultList>("post", "/main/outsource/getSendOrderList", {
    data
  }, null, false, ['outsource_send']);
};
//获取外发单列表数量
export const getSendOrderCount = (data?: object) => {
  return http.request<ResultCount>("post", "/main/outsource/getSendOrderCount", {
    data
  }, null, false, ['outsource_send']);
};

//获取外接单列表
export const getOutsourceDetailList = (data?: object) => {
  return http.request<ResultList>("post", "/main/outsource/getOutsourceDetailList", {
    data
  });
};
//获取外接单列表数量
export const getOutsourceDetailCount = (data?: object) => {
  return http.request<ResultCount>("post", "/main/outsource/getOutsourceDetailCount", {
    data
  }, null, false, ['outsource_send', 'outsource_receive']);
};

//获取可出货列表
export const getCanSendData = (data?: object) => {
  return http.request<ResultList>("post", "/main/outsource/getCanSendData", {
    data
  }, null, false, ['outsource_transfer']);
};

//获取可收货列表
export const getCanReceiveData = (data?: object) => {
  return http.request<ResultList>("post", "/main/outsource/getCanReceiveData", {
    data
  });
};
//批量出货
export const bulkSendGoods = (data?: object) => {
  return http.request<ResultList>("post", "/main/outsource/bulkSendGoods", {
    data
  });
};
//批量收货
export const bulkReceiveGoods = (data?: object) => {
  return http.request<ResultList>("post", "/main/outsource/bulkReceiveGoods", {
    data
  });
};
//出货收货记录
export const getTransferRecordList = (data?: object) => {
  return http.request<ResultList>("post", "/main/outsource/getTransferRecordList", {
    data
  });
};
//出货收货记录数量
export const getTicketRecordCount = (data?: object) => {
  return http.request<ResultCount>("post", "/main/outsource/getTicketRecordCount", {
    data
  });
};
//获取外发单统计
export const getSendOrderStat = (data?: object) => {
  return http.request<ResultObject>("post", "/main/outsource/getSendOrderStat", {
    data
  }, null, false, ['outsource_send']);
};
//获取外发外接单详细
export const getOutsourceDetail = (data?: object) => {
  return http.request<ResultObject>("post", "/main/outsource/getOutsourceDetail", {
    data
  });
};
//获取外接单统计
export const getReceiveOrderStat = (data?: object) => {
  return http.request<ResultObject>("post", "/main/outsource/getOutsourceDetailStat", {
    data
  }, null, false, ['outsource_send', 'outsource_receive']);
};
//获取外接单统计
export const getCanCheckOutsourceStat = (data?: object) => {
  return http.request<ResultObject>("post", "/main/outsource/getCanCheckOutsourceStat", {
    data
  });
};
//获取可核对的外发单
export const getCanCheckOutsource = (data?: object) => {
  return http.request<ResultList>("post", "/main/outsource/getCanCheckOutsource", {
    data
  });
};
//核对的外发单
export const checkOutsource = (data?: object) => {
  return http.request<ResultObject>("post", "/main/outsource/checkOutsource", {
    data
  });
};
//修改对账单价
export const updatePrice = (data?: object) => {
  return http.request<ResultObject>("post", "/main/outsource/updatePrice", {
    data
  });
};

//设置订单完成
export const setOrderCompleted = (data?: object) => {
  return http.request<ResultObject>("post", "/main/order/setCompleted", {
    data
  });
};
//设置订单锁住
export const setLock = (data?: object) => {
  return http.request<ResultObject>("post", "/main/order/setLock", {
    data
  });
};
//设置订单裁完
export const setCutComplete = (data?: object) => {
  return http.request<ResultObject>("post", "/main/order/setCutComplete", {
    data
  });
};



//********************外发********************** */


//接收外发单
export const receiveOutsource = (data?: object) => {
  return http.request<ResultStr>("post", "/main/outsource/receive", {
    data
  });
};
//取消外发
export const cancelOutsource = (data?: object) => {
  return http.request<ResultStr>("post", "/main/outsource/cancel", {
    data
  });
};
//外发单详细
export const outsourceDetail = (data?: object) => {
  return http.request<ResultObject>("post", "/main/outsource/getDetail", {
    data
  });
};
//获取外发包票
export const getOutsourceTicket = (data?: object) => {
  return http.request<ResultList>("post", "/main/outsource/getTicket", {
    data
  });
};
//收货出货记录
export const receiveAndSendRecord = (data?: object) => {
  return http.request<ResultList>("post", "/main/outsource/getRecordList", {
    data
  });
};
//出货
export const sendGoods = (data?: object) => {
  return http.request<ResultList>("post", "/main/outsource/sendGoods", {
    data
  });
};
//收货
export const receiveGoods = (data?: object) => {
  return http.request<ResultList>("post", "/main/outsource/receiveGoods", {
    data
  });
};
//删除出货、收货记录
export const deleteRecord = (data?: object) => {
  return http.request<ResultList>("post", "/main/outsource/deleteRecord", {
    data
  });
};
//手动完成
export const setCompleted = (data?: object) => {
  return http.request<ResultList>("post", "/main/outsource/setCompleted", {
    data
  });
};
//拒绝
export const refuse = (data?: object) => {
  return http.request<ResultList>("post", "/main/outsource/refuse", {
    data
  });
};
//对账结算
export const settle = (data?: object) => {
  return http.request<ResultList>("post", "/main/outsource/settle", {
    data
  });
};

//导出
export const exportList = (data?: object) => {
  return http.request<ResultStr>("post", "/main/order/exportList", {
    data
  });
};
//导出外发外接
export const exportRecord = (data?: object) => {
  return http.request<ResultStr>("post", "/main/outsource/exportList", {
    data
  });
};
//导出外发外接
export const exportOutsourceDetailList = (data?: object) => {
  return http.request<ResultStr>("post", "/main/outsource/exportOutsourceDetailList", {
    data
  });
};

//订单统计
export const orderStat = (data?: object) => {
  return http.request<ResultObject>("post", "/main/order/stat", {
    data
  });
};

//年度汇总统计
export const getYearSummary = (data?: object) => {
  return http.request<ResultList>("post", "/main/order/getYearSummary", {
    data
  });
};
//按款式汇总
export const getStyleSummary = (data?: object) => {
  return http.request<ResultList>("post", "/main/order/getStyleSummary", {
    data
  });
};
//按款式汇总详情
export const getStyleSummaryDetail = (data?: object) => {
  return http.request<ResultList>("post", "/main/order/getStyleSummaryDetail", {
    data
  });
};

//按款式汇总详情
export const exportStyleSummary = (data?: object) => {
  return http.request<ResultList>("post", "/main/order/exportStyleSummary", {
    data
  });
};

//按款式汇总详情导出
export const exportStyleSummaryDetail = (data?: object) => {
  return http.request<ResultList>("post", "/main/order/exportStyleSummaryDetail", {
    data
  });
};

//按款式汇总详情导出
export const outsourceStat = (data?: object) => {
  return http.request<ResultList>("post", "/main/outsource/stat", {
    data
  });
};

//获取可接收款式
export const getCanReceivedGoods = (data?: object) => {
  return http.request<ResultList>("post", "/main/outsource/getCanReceivedGoods", {
    data
  });
};

//订单成本预算
export const generateOrderBudgetCost = (data?: object) => {
  return http.request<ResultList>("post", "/main/order/generateBudgetCost", {
    data
  }, null, false, ['order_cost']);
};

//关联主料订单
export const bindMainMaterial = (data?: object) => {
  return http.request<ResultObject>("post", "/main/order/bindMainMaterial", {
    data
  });
};

//修改外发信息
export const updateOutSourceDetail = (data?: object) => {
  return http.request<ResultObject>("post", "/main/outsource/updateDetail", {
    data
  });
};

//更新多单价
export const recalculateMultiPrice = (data?: object) => {
  return http.request<ResultObject>("post", "/main/work/recalculateMultiPrice", {
    data
  });
};

//获取订单工序完成明细
export const getStepsComplete = (data?: object) => {
  return http.request<ResultList>("post", "/main/order/getStepsComplete", {
    data
  });
};

//获取订单关联的出入库单
export const getMovementList = (data?: object) => {
  return http.request<ResultList>("post", "/main/order/related/getMovementList", {
    data
  });
};
//获取订单关联的采购单
export const getPurchaseList = (data?: object) => {
  return http.request<ResultList>("post", "/main/order/related/getPurchaseList", {
    data
  });
};

//获取裁床单列表
export const getMachineList = (data?: object) => {
  return http.request<ResultList>("post", "/main/order/machine/getList", {
    data
  }, null, false, ['order_view', 'order_report_cut']);
};

//获取裁床单列表数量
export const getMachineListCount = (data?: object) => {
  return http.request<ResultCount>("post", "/main/order/machine/getCount", {
    data
  }, null, false, ['order_view', 'order_report_cut']);
};

//获取裁床单明细
export const getMachineDetail = (data?: object) => {
  return http.request<ResultObject>("post", "/main/order/machine/getDetail", {
    data
  }, null, false, ['order_view', 'order_report_cut']);
};
//修改床次详细
export const updateMachine = (data?: object) => {
  return http.request<ResultObject>("post", "/main/order/machine/update", {
    data
  });
};

//导出裁床单列表
export const exportMachineList = (data?: object) => {
  return http.request<ResultObject>("post", "/main/order/machine/exportList", {
    data
  });
};
//导出裁床单列表
export const exportMachineDetail = (data?: object) => {
  return http.request<ResultObject>("post", "/main/order/machine/exportDetail", {
    data
  });
};


//每月工序完成量
export const monthlyStepComplete = (data?: object) => {
  return http.request<ResultList>("post", "/main/order/report-form/monthlyStepComplete", {
    data
  });
};
//每日工序完成量
export const dailyStepComplete = (data?: object) => {
  return http.request<ResultList>("post", "/main/order/report-form/dailyStepComplete", {
    data
  });
};
//设置工序目标
export const setStepTarget = (data?: object) => {
  return http.request<ResultObject>("post", "/main/order/report-form/setStepTarget", {
    data
  });
};
//相同工序目标
export const setSameStepTarget = (data?: object) => {
  return http.request<ResultObject>("post", "/main/order/report-form/setSameStepTarget", {
    data
  });
};
//清除工序目标
export const clearStepTarget = (data?: object) => {
  return http.request<ResultObject>("post", "/main/order/report-form/clearStepTarget", {
    data
  });
};
//获取工序目标
export const getStepTarget = (data?: object) => {
  return http.request<ResultObject>("post", "/main/order/report-form/getStepTarget", {
    data
  });
};
//按裁床汇总
export const getCutterSummary = (data?: object) => {
  return http.request<ResultList>("post", "/main/order/machine/getCutterSummary", { data }, null, false, ['order_view', 'order_report_cut']);
};

/**
 * 部门生产进度报表*
 */
//每月部门完成量
export const monthlyDepartmentComplete = (data?: object) => {
  return http.request<ResultList>("post", "/main/order/report-form/monthlyDepartmentComplete", {
    data
  });
};
//每日部门完成量
export const dailyDepartmentComplete = (data?: object) => {
  return http.request<ResultList>("post", "/main/order/report-form/dailyDepartmentComplete", {
    data
  });
};
//每日部门完成情况
export const getDepartmentComplete = (data?: object) => {
  return http.request<ResultList>("post", "/main/order/report-form/getDepartmentComplete", {
    data
  });
};
//设置部门目标
export const setDepartmentTarget = (data?: object) => {
  return http.request<ResultObject>("post", "/main/order/report-form/setDepartmentTarget", {
    data
  });
};
//相同部门目标
export const setSameDepartmentTarget = (data?: object) => {
  return http.request<ResultObject>("post", "/main/order/report-form/setSameDepartmentTarget", {
    data
  });
};
//清除部门目标
export const clearDepartmentTarget = (data?: object) => {
  return http.request<ResultObject>("post", "/main/order/report-form/clearDepartmentTarget", {
    data
  });
};
//获取部门目标
export const getDepartmentTarget = (data?: object) => {
  return http.request<ResultObject>("post", "/main/order/report-form/getDepartmentTarget", {
    data
  });
};