import { defineStore } from 'pinia'
import axios from "axios";
const userDepartmentTree = defineStore('departmentTree', {
  state: () => ({
    data: {}
  }),
  getters: {},
  actions: {
    async setDepartmentTree(data) {
      try {
        //获取系统的一些基础配置信息       
        this.data = data;
      } catch (error) {
        console.log(error)
      }

    },
    getDepartmentTree() {
      return this.data;
    },
  }
})
export default userDepartmentTree