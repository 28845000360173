import { http } from "@/utils/http";
import {
  ResultCount,
  ResultList,
  ResultObject,
  ResultStr,
  ResultObjectStyle
} from "../types";

//保存
export const saveWarehouse = (data?: object) => {
  return http.request<ResultObject>("post", "/main/warehouse/save", { data });
};
//获取仓库列表
export const getWarehouses = (data?: object) => {
  return http.request<ResultList>("post", "/main/warehouse/getList", { data });
};
//获取仓库详情
export const getWarehouseDetail = (data?: object) => {
  return http.request<ResultObject>("post", "/main/warehouse/getDetail", { data });
};

export const delWarehouse = (data?: object) => {
  return http.request<ResultObject>("post", "/main/warehouse/delete", { data });
};
/* ****************************货架库位start***********************************************/

//生成货架编号
export const generateNo = (data?: object) => {
  return http.request<ResultObject>("post", "/main/rack/generateNo", { data });
};

//生成库位
export const generateLocation = (data?: object) => {
  return http.request<ResultList>("post", "/main/rack/generateLocation", { data });
};

//保存货架
export const saveRack = (data?: object) => {
  return http.request<ResultObject>("post", "/main/rack/save", { data });
};
//货架详情
export const getRackDetail = (data?: object) => {
  return http.request<ResultObject>("post", "/main/rack/getDetail", { data });
};
//删除货架
export const delRack = (data?: object) => {
  return http.request<ResultObject>("post", "/main/rack/delete", { data });
};
//货架列表
export const getRackList = (data?: object) => {
  return http.request<ResultList>("post", "/main/rack/getList", { data });
};
//货架总数
export const getRackCount = (data?: object) => {
  return http.request<ResultCount>("post", "/main/rack/getCount", { data });
};
//创建库位
export const createLocation = (data?: object) => {
  return http.request<ResultObject>("post", "/main/rack/createLocation", { data });
};
//删除库位
export const deleteLocation = (data?: object) => {
  return http.request<ResultObject>("post", "/main/rack/deleteLocation", { data });
};
//库位列表
export const getLocationList = (data?: object) => {
  return http.request<ResultList>("post", "/main/rack/getLocationList", { data });
};
//库位总数
export const getLocationCount = (data?: object) => {
  return http.request<ResultCount>("post", "/main/rack/getLocationCount", { data });
};
//获取楼层区域选项
export const getOptions = (data?: object) => {
  return http.request<ResultObject>("post", "/main/rack/getOptions", { data });
};

/* ****************************货架库位end***********************************************/

