<template>
  <mhg-form-input label="关联">
    <div class="flex items-center">
      <el-select v-model="curTab" @change="curTabChange" placeholder="类型" class="!w-[100px] radius-left-12">
        <el-option
          v-for="item in [
            { label: '关联订单', value: 'order' },
            { label: '关联款式', value: 'style' },
          ]"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
      <div class="select-content flex items-center" :style="{ width: width + 'px' }" @click="openDialog">
        <el-tag closable @close="delSelect" v-if="selectedData?.length">
          <div :style="{ maxWidth: width - 110 + 'px !important' }" class="no-wrap !min-w-[30px]">
            <span :title="selectedData?.[0]?.order_no" v-if="curTab == 'order'">{{ selectedData?.[0]?.order_no }}</span>
            <span :title="selectedData?.[0]?.style_no" v-if="curTab == 'style'">{{ selectedData?.[0]?.style_no }}</span>
          </div>
        </el-tag>
        <el-tag class="ml-3" v-if="selectedData?.length > 1">
          <span class="no-wrap !max-w-[50px] !min-w-[20px]"> +{{ selectedData.length - 1 }} </span>
        </el-tag>
        <el-icon @click.stop="saveSelect('clearAll')" v-if="selectedData?.length && multiple" class="close-icon"><CircleClose /></el-icon>
      </div>
    </div>
  </mhg-form-input>
  <mhg-form-input label="关联床次" v-if="curTab == 'order'">
    <el-select v-model="select_machine_ids" filterable multiple clearable placeholder="关联床次" @change="sendInfoMachine" class="!w-[250px]">
      <el-option v-for="item in machines" :key="item.id" :label="item.machine" :value="item.id" />
    </el-select>
  </mhg-form-input>

  <!------选择订单------->
  <el-dialog v-model="orderDialog" title="选择订单" class="radius12" width="800px" top="1vh">
    <div class="flex items-center justify-between">
      <div class="mb-3">
        <el-input placeholder="订单编号" v-model.trim="serachOrderData.keyword" @clear="onSearchOrder" @keyup.enter.native="onSearchOrder" clearable class="!w-[200px]">
          <template #suffix>
            <el-icon @click="onSearchOrder" class="cursor-pointer"><search /></el-icon>
          </template>
        </el-input>
        <el-button @click="onSearchOrder" class="ml-3">搜索</el-button>
      </div>
      <el-button type="primary" plain @click="saveSelect('order')">保存</el-button>
    </div>
    <div id="order_relative_table_id"></div>
    <vxe-table
      round
      ref="orderTableRef"
      id="order_relative_table"
      border
      auto-resize
      :data="orderList"
      :row-config="{ keyField: 'id' }"
      :column-config="{ resizable: true }"
      :custom-config="{ storage: true }"
      :checkbox-config="{ trigger: 'row' }"
      @checkbox-all="selectOrderAllChangeEvent"
      @checkbox-change="selectOrderChangeEvent"
      @radio-change="orderRadioChangeEvent"
      :maxHeight="$getInfoById('order_relative_table_id')?.contentHeight - 140"
      :Key="tableTimeKey"
    >
      <vxe-column v-if="multiple" type="checkbox" width="60"></vxe-column>
      <vxe-column v-else type="radio" width="60"></vxe-column>
      <vxe-column type="seq" width="60"></vxe-column>
      <vxe-column field="picture_main" title="图片" width="80">
        <template #default="{ row }">
          <el-image
            fit="cover"
            class="table-main-img !p-0 !m-0"
            v-if="row.style?.picture_main"
            :src="row.style?.picture_main"
            :preview-src-list="[row.style?.picture_main]"
            lazy
            :preview-teleported="true"
          />
          <el-empty class="!p-0 overflow-hidden" v-else :image-size="60" description=" " />
        </template>
      </vxe-column>
      <vxe-column field="order_no" title="订单编号" min-width="100"></vxe-column>
      <vxe-column field="machine" title="床次" min-width="100"></vxe-column>
    </vxe-table>
    <div class="mt-3 pb-9 overflow-hidden">
      <el-pagination
        class="fr"
        v-model:current-page="serachOrderData.page_index"
        :page-size="serachOrderData.page_size"
        :page-sizes="[8, 10, 20, 40, 80, 100, 200]"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageOrderCount"
        @size-change="onOrderSizeChange"
        @current-change="onOrderPageChange"
      />
    </div>
  </el-dialog>
  <!--------选择款式-------->
  <el-dialog v-model="styleDialog" title="选择款式" class="radius12" width="800px" top="1vh">
    <div class="flex items-center justify-between">
      <div class="mb-3">
        <el-input placeholder="编号/名称" v-model.trim="serachStyleData.keyword" @clear="onSearchStyle" @keyup.enter.native="onSearchStyle" clearable class="!w-[200px]">
          <template #suffix>
            <el-icon @click="onSearchStyle" class="cursor-pointer"><search /></el-icon>
          </template>
        </el-input>
        <el-button @click="onSearchStyle" class="ml-3">搜索</el-button>
      </div>
      <el-button type="primary" plain @click="saveSelect('style')">保存</el-button>
    </div>
    <div id="style_relative_table_id"></div>
    <vxe-table
      round
      ref="styleTableRef"
      id="style_relative_table"
      border
      auto-resize
      :data="styleList"
      :row-config="{ keyField: 'id' }"
      :column-config="{ resizable: true }"
      :custom-config="{ storage: true }"
      :checkbox-config="{ trigger: 'row' }"
      @checkbox-all="selectStyleAllChangeEvent"
      @checkbox-change="selectStyleChangeEvent"
      :maxHeight="$getInfoById('style_relative_table_id')?.contentHeight - 140"
      :Key="tableTimeKey"
      @radio-change="styleRadioChangeEvent"
    >
      <vxe-column v-if="multiple" type="checkbox" width="60"></vxe-column>
      <vxe-column v-else type="radio" width="60"></vxe-column>
      <vxe-column type="seq" width="60"></vxe-column>
      <vxe-column field="picture_main" title="图片" width="80">
        <template #default="{ row }">
          <el-image
            fit="cover"
            class="table-main-img !p-0 !m-0"
            v-if="row.picture_main"
            :src="row.picture_main"
            :preview-src-list="[row.picture_main]"
            lazy
            :preview-teleported="true"
          />
          <el-empty class="!p-0 overflow-hidden" v-else :image-size="60" description=" " />
        </template>
      </vxe-column>
      <vxe-column field="style_no" title="款式编号" min-width="100"></vxe-column>
      <vxe-column field="name" title="款式名称" min-width="100"></vxe-column>
    </vxe-table>
    <div class="mt-3 pb-9 overflow-hidden">
      <el-pagination
        class="fr"
        v-model:current-page="serachStyleData.page_index"
        :page-size="serachStyleData.page_size"
        :page-sizes="[8, 10, 20, 40, 80, 100, 200]"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageStyleCount"
        @size-change="onStyleSizeChange"
        @current-change="onStylePageChange"
      />
    </div>
  </el-dialog>
</template>

<script setup lang="ts">
import { ref, reactive, onMounted, getCurrentInstance, watch } from "vue";
const { proxy } = getCurrentInstance();
import { message } from "@/utils/message";
import { isEmpty, uniqBy, intersectionBy, remove, pullAllBy } from "lodash-es";
import orderSelect from "@/components/select/orderSelect.vue";
// import categorySelect from "@/components/select/categorySelect.vue";
import styleSelect from "@/components/select/styleSelect.vue";
import { VxeTableInstance, VxeTableEvents } from "vxe-table";
import { getOrderList, getOrdersCount, orderDetail } from "@/api/order";
import { getCategorys, getCategoryCount, categoryDetail } from "@/api/category";
const props = defineProps({
  from: {
    type: String,
    default: "",
  },
  multiple: {
    type: Boolean,
    default: true,
  },
  order_id: {
    type: Number,
    default: null,
  },
  style_id: {
    type: Number,
    default: null,
  },
  order_ids: {
    //多选
    type: Array,
    default: null,
  },
  style_ids: {
    //多选
    type: Array,
    default: null,
  },
  machine_ids: {
    //多选
    type: Array,
    default: null,
  },
  width: {
    type: Number,
    default: 150,
  },
  select_warehouse_first: {
    //是否需要先选择仓库
    type: Boolean,
    default: false,
  },
  warehouse_id: {
    //仓库id，用于判断是否需要先选择仓库
    type: Number,
    default: null,
  },
});
let select_machine_ids = ref([]);
let machines = ref([]);
const emits = defineEmits(["change", "update:style_id", "update:order_id", "update:style_ids", "update:order_ids", "update:machine_ids"]);
let curTab = ref("order");
function curTabChange() {
  switch (curTab.value) {
    case "order":
      selectedData.value = [];
      selectedStyleRecords.value = [];
      break;
    case "style":
      selectedData.value = [];
      selectedOrderRecords.value = [];
      break;
  }
  sendInfo();
}
let orderDialog = ref(false);
let styleDialog = ref(false);
function openDialog() {
  if (props.select_warehouse_first && !props.warehouse_id) {
    return message("请先选择仓库");
  }
  switch (curTab.value) {
    case "order":
      onGetOrderList(serachOrderData.value, true);
      orderDialog.value = true;
      break;
    case "style":
      onGetCategorys(serachStyleData.value, true);
      styleDialog.value = true;
      break;
  }
}

//保存选中
let selectedData = ref([]);
async function saveSelect(type) {
  switch (type) {
    case "order":
      selectedData.value = proxy.$noBind(selectedOrderRecords.value);
      orderDialog.value = false;
      machines.value = [];
      for (let i = 0; i < selectedData.value.length; i++) {
        let resOrder: any = await getOrderDetail({ id: selectedData.value[i].id });
        machines.value = machines.value.concat(resOrder.machines);
      }
      break;
    case "style":
      selectedData.value = proxy.$noBind(selectedStyleRecords.value);
      styleDialog.value = false;

      break;
    case "clearAll":
      selectedData.value = [];
      selectedOrderRecords.value = [];
      selectedStyleRecords.value = [];
      break;
  }
  sendInfo();
}
function sendInfo() {
  let ids = selectedData.value.map((item) => {
    return item.id;
  });
  if (curTab.value == "order") {
    if (props.multiple) {
      emits("update:order_ids", ids);
      emits("update:style_ids", []);
    } else {
      emits("update:order_id", ids?.[0].id || null);
    }
  } else if (curTab.value == "style") {
    if (props.multiple) {
      emits("update:style_ids", ids);
      emits("update:order_ids", []);
      emits("update:machine_ids", []);
    } else {
      emits("update:style_id", ids?.[0].id || null);
    }
  }
  emits("change", { data: selectedData.value, ids, type: curTab.value });
}
function sendInfoMachine() {
  emits("update:machine_ids", select_machine_ids.value);
}
function delSelect() {
  if (curTab.value == "order") {
    selectedOrderRecords.value.splice(0, 1);
  } else {
    selectedStyleRecords.value.splice(0, 1);
  }
  selectedData.value.splice(0, 1);
  sendInfo();
}

/*******************选择订单相关sart******************************* */
/**
 * 关联订单 */
let serachOrderData = ref({
  keyword: "",
  page_index: 1,
  page_size: 10,
  is_build: true,
});
//选中的订单数据，用于分页后的回显，保存数据
let selectedOrders = ref([]);
const orderList = ref([]);
let tableTimeKey = ref(null);
const onGetOrderList = (data, isPage) => {
  orderList.value = [];
  getOrderList(data).then((res) => {
    if (res) {
      tableTimeKey.value = new Date().getTime();
      orderList.value = res.data;
      // if (!isEmpty(initOrderObj.value) && data.page_index == 1) {
      //   orderList.value = orderList.value.concat(initOrderObj.value);
      // }
      orderList.value = uniqBy(orderList.value, "id");
      let array = intersectionBy(orderList.value, selectedOrderRecords.value, "id");
      console.log(array, "arry");
      const $table = orderTableRef.value;
      if ($table) {
        if (props.multiple) {
          $table.setCheckboxRow(array, true);
        } else {
          $table.setRadioRow(initOrderObj.value[0]);
        }
      }

      if (isPage) {
        onGetOrdersCount(data);
      }
    }
  });
};
let pageOrderCount = ref(0);
const onGetOrdersCount = (data) => {
  getOrdersCount(data).then((res) => {
    if (res) {
      pageOrderCount.value = res.data;
    }
  });
};
const onOrderSizeChange = (size) => {
  serachOrderData.value.page_index = 1;
  serachOrderData.value.page_size = size;
  onGetOrderList(serachOrderData.value, true);
};
const onOrderPageChange = (currentPage) => {
  serachOrderData.value.page_index = currentPage;
  onGetOrderList(serachOrderData.value, true);
};
const orderTableRef = ref<VxeTableInstance>();
let selectedOrderRecords = <any>ref([]);
const selectOrderChangeEvent: VxeTableEvents.CheckboxChange = ({ checked, row }) => {
  if (checked) {
    selectedOrderRecords.value.push(row);
  } else {
    remove(selectedOrderRecords.value, (o: any) => {
      return o.id == row.id;
    });
  }
};
const selectOrderAllChangeEvent: VxeTableEvents.CheckboxAll = ({ checked, rows }) => {
  if (checked) {
    selectedOrderRecords.value = selectedOrderRecords.value.concat(orderList.value);
    selectedOrderRecords.value = uniqBy(selectedOrderRecords.value, "id");
  } else {
    pullAllBy(selectedOrderRecords.value, orderList.value, "id");
  }
};
function onSearchOrder() {
  serachOrderData.value.page_index = 1;
  onGetOrderList(serachOrderData.value, true);
}
const orderRadioChangeEvent: VxeTableEvents.RadioChange = ({ row }) => {
  selectedOrderRecords.value = [row];
};

/*******************选择订单相关end******************************* */

/*******************选择款式相关sart******************************* */
/**
 * 关联订单 */
let serachStyleData = ref({
  keyword: "",
  page_index: 1,
  page_size: 10,
});
//选中的订单数据，用于分页后的回显，保存数据
let selectedStyles = ref([]);
const styleList = ref([]);
const onGetCategorys = (data, isPage) => {
  styleList.value = [];
  getCategorys(data).then((res) => {
    if (res) {
      tableTimeKey.value = new Date().getTime();
      styleList.value = res.data;
      // if (initStyleObj.value) {
      //   styleList.value = styleList.value.concat(initStyleObj.value);
      // }
      styleList.value = uniqBy(styleList.value, "id");
      let array = intersectionBy(styleList.value, selectedStyleRecords.value, "id");
      const $table = styleTableRef.value;
      if ($table) {
        if (props.multiple) {
          $table.setCheckboxRow(array, true);
        } else {
          $table.setRadioRow(initStyleObj.value[0]);
        }
      }
      if (isPage) {
        onGetCategoryCount(data);
      }
    }
  });
};
let pageStyleCount = ref(0);
const onGetCategoryCount = (data) => {
  getCategoryCount(data).then((res) => {
    if (res) {
      pageStyleCount.value = res.data;
    }
  });
};
const onStyleSizeChange = (size) => {
  serachStyleData.value.page_index = 1;
  serachStyleData.value.page_size = size;
  onGetCategorys(serachStyleData.value, true);
};
const onStylePageChange = (currentPage) => {
  serachStyleData.value.page_index = currentPage;
  onGetCategorys(serachStyleData.value, true);
};
const styleTableRef = ref<VxeTableInstance>();
let selectedStyleRecords = <any>ref([]);
const selectStyleChangeEvent: VxeTableEvents.CheckboxChange = ({ checked, row }) => {
  if (checked) {
    selectedStyleRecords.value.push(row);
  } else {
    remove(selectedStyleRecords.value, (o: any) => {
      return o.id == row.id;
    });
  }
};
const selectStyleAllChangeEvent: VxeTableEvents.CheckboxAll = ({ checked, rows }) => {
  if (checked) {
    selectedStyleRecords.value = selectedStyleRecords.value.concat(orderList.value);
    selectedStyleRecords.value = uniqBy(selectedStyleRecords.value, "id");
  } else {
    pullAllBy(selectedStyleRecords.value, orderList.value, "id");
  }
};
function onSearchStyle() {
  serachStyleData.value.page_index = 1;
  onGetCategorys(serachStyleData.value, true);
}
const styleRadioChangeEvent: VxeTableEvents.RadioChange = ({ row }) => {
  selectedStyleRecords.value = [row];
};
/*******************选择款式相关end******************************* */

/******************初始化数据start*********************** */

let initOrderObj = ref([]);
let initStyleObj = ref([]);
async function initData() {
  initOrderObj.value = [];
  initStyleObj.value = [];
  selectedData.value = [];
  // debugger
  if (props.multiple) {
    //多选
    if (!isEmpty(props.order_ids)) {
      for (let i = 0; i < props.order_ids.length; i++) {
        let item = props.order_ids[i];
        let order: any = await getOrderDetail({ id: item });
        machines.value = machines.value.concat(order.machines);
        selectedData.value.push(order);
        initOrderObj.value.push(order);
        selectedOrderRecords.value.push(order); //默认选中当前已经有的订单
      }
    }
    if (!isEmpty(props.style_ids)) {
      for (let i = 0; i < props.style_ids.length; i++) {
        let item = props.style_ids[i];
        let style = await getCategoryDetail({ id: item });
        selectedData.value.push(style);
        initStyleObj.value.push(style);
        selectedStyleRecords.value.push(style); //默认选中当前已经有的订单
      }
    }
  } else {
    if (props.order_id) {
      curTab.value = "order";
      orderDetail({ id: props.order_id }).then((res) => {
        if (res) {
          initOrderObj.value = [res.data];
          selectedData.value = [res.data];
        }
      });
    } else if (props.style_id) {
      curTab.value = "style";
      categoryDetail({ id: props.style_id }).then((res) => {
        if (res) {
          initStyleObj.value = [res.data];
          selectedData.value = [res.data];
        }
      });
    }
  }
  selectedData.value = uniqBy(selectedData.value, "id");
}
async function getOrderDetail(data) {
  let resData = <any>[];
  resData = await orderDetail(data)
    .then((res) => {
      return res.data;
    })
    .catch(() => {});

  return resData;
}
async function getCategoryDetail(data) {
  let resData = <any>[];
  resData = await categoryDetail(data)
    .then((res) => {
      return res.data;
    })
    .catch(() => {});

  return resData;
}
/******************初始化数据end*********************** */
onMounted(() => {
  initData();
});
watch(
  () => props.order_id,
  (val) => {
    initData();
  },
  {
    deep: true,
  }
);
watch(
  () => props.style_id,
  (val) => {
    initData();
  },
  {
    deep: true,
  }
);
</script>
<style scoped>
.select-content {
  border: 1px solid var(--el-border-color);
  border-left: 0px;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  background: #fff;
  line-height: 24px;
  height: 32px;
  padding: 2px 12px;
  position: relative;
  text-align: left;
  cursor: pointer;
  position: relative;
}
.select-content:hover .close-icon {
  display: block;
}
.close-icon {
  position: absolute;
  color: var(--color);
  top: 0;
  right: 10px;
  bottom: 0;
  margin: auto;
  display: none;
}
</style>
