import settingsvg from "../../assets/svg/setting.svg";
import { getRank } from '../rank'
let rank = getRank('setting')
export default {
  path: "/setting",
  redirect: "/setting/index",
  name: "setting_index",
  meta: {
    icon: settingsvg,
    title: "系统设置",
    rank,
    roles: ['production_router']
  },
  children: [
    {
      path: "/setting/index",
      name: "setting",
      component: () => import("@/views/setting/index.vue"),
      meta: {
        title: "设置管理",
        keepAlive: false,
        roles:['setting_manager']
      }
    },

  ]
} as RouteConfigsTable;
