<template>
  <div class="address w-[100%]">
    <div class="selete-address flex">
      <el-select class="radius12" v-model="addressData.province" filterable default-first-option placeholder="省份" @change="changeProvince">
        <el-option v-for="(item, index) in areaData" :key="index" :label="item.name" :value="item.name" />
      </el-select>
      <el-select class="radius12 ml-3" v-model="addressData.city" filterable default-first-option placeholder="城市" @change="changeCity">
        <el-option v-for="(item, index) in thisAreaData.city" :key="index" :label="item.name" :value="item.name" />
      </el-select>
      <el-select class="radius12 ml-3" v-model="addressData.area" filterable default-first-option placeholder="地区" @change="sendInfo" :disabled="areaDisable">
        <el-option v-for="(item, index) in thisAreaData.area" :key="index" :label="item.name" :value="item.name" />
      </el-select>
    </div>
    <el-input v-model="addressData.street" placeholder="详细地址" @input="sendInfo" />
  </div>
</template>
<script setup lang="ts">
import { ref, reactive, onMounted,watch } from "vue";
import { isEmpty } from "lodash-es";
const props = defineProps({
  initData: {
    type: Object,
    default: {},
  },
  address: {
    type: Object,
    default: {},
  },
});

let addressData = <any>ref({
  province: null,
  city: null,
  area: null,
  street: null,
});
const areaData = ref([]);
const thisAreaData = reactive({
  city: null,
  area: null,
});
//省份
function changeProvince(province, bool) {
  thisAreaData.city = areaData.value.find((item, index) => {
    if (item.name == province) {
      return true;
    }
  }).children;
  if (!bool && addressData.value) {
    addressData.value.city = "";
    addressData.value.area = "";
  }
}
//城市
const areaDisable = ref(true);
function changeCity(city, bool) {
  const temp = thisAreaData.city.find((item, index) => {
    if (item.name == city) {
      return true;
    }
  });
  if (temp.children) {
    thisAreaData.area = temp.children;
    areaDisable.value = false;
  } else {
    thisAreaData.area = [];
    areaDisable.value = true;
  }
  if (!bool) {
    addressData.value.area = null;
  }
}

//传递消息给父组件
const emits = defineEmits(["change", "update:address"]);
function sendInfo() {
  emits("change", { data: addressData.value });
  emits("update:address", addressData.value);
}
onMounted(() => {
  let url = import.meta.env.VITE_PUBLIC_PATH + "city.min.json";
  fetch(url)
    .then((response) => response.json())
    .then((res) => {
      areaData.value = res;
    });
  if (!isEmpty(props.initData)) {
    addressData.value.province = props.initData.province;
    addressData.value.city = props.initData.city;
    addressData.value.area = props.initData.area;
    addressData.value.street = props.initData.street;
  }
  if (!isEmpty(props.address)) {
    addressData.value = props.address;
  }
});
watch(()=>props.address,(val)=>{
  addressData.value = val;
})
</script>
<style scoped>
.selete-address {
  margin-bottom: 5px;
  font-size: 0;
}

.selete-address .el-select {
  width: 33.3%;
}
</style>
