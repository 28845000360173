import { useUserInfo } from "@/store/modules/useInfo";
import { isEmpty, isArray, isObject, isNull, uniq, uniqBy, groupBy } from "lodash-es";
import { http } from "@/utils/http";
import { fixedFloat, sortSizes } from "@mhg/utils";
import { assign } from "xe-utils";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
dayjs.extend(duration);
declare module "vue" {
  interface ComponentCustomProperties {
    $checkAuth: any;
    $noBind: any;
    $isEmpty: any;
    $formatNum: any;
    $mhgSetItem: any;
    $mhgGetItem: any;
    $formatSizeKey: any;
    $calTotalColorQuantity: any;
    $getStaticImage: any;
    $addr: any;
    $getInfoById: any;
    $getInfoByRef: any;
    $orderStatusStr: any;
    $sendOrderStatusStr: any;
    $getColorAndSize: any;
    $formatPay: any;
    $digitUppercase: any;
    $formatDesignStatus: any;
    $formatSettleStatus: any;
    $formatAuditStatus: any;
    $formatLendStatus: any;
    $formatChangeType: any;
    $timeToMinute: any;
    $minuteToTime: any;
    $showMinuteToTime: any;
    $durationHour: any;
    $minuteToHour: any;
    $exportBaseUrl: any;
  }
}
/**
 * 导出的基础路径
 */
function exportBaseUrl(): string {
  let str: string = import.meta.env.VITE_API_URL;
  let host = window.location.origin
  if (host.indexOf('mianhuaguo') != -1) {
    str = import.meta.env.VITE_API_URL
  } else {
    str = import.meta.env.VITE_API_DEV_URL
  }
  return str
}
function getInfoById(id) {
  let width = document.body.clientWidth
  let height = document.body.clientHeight
  let contentHeight = 0;
  let idEle = null;
  let idClientRect = null;
  if (id) {
    idEle = document.getElementById(id)
    if (!idEle) return
    idClientRect = document.getElementById(id).getBoundingClientRect()
    contentHeight = height - idClientRect.top
  }
  return { bodyWidth: width, bodyHeight: height, idEle: idEle, idClientRect: idClientRect, contentHeight }
}
function getInfoByRef(ref) {
  let width = document.body.clientWidth
  let height = document.body.clientHeight
  let contentHeight = 0;
  let idClientRect = null;
  if (ref) {
    idClientRect = ref.getBoundingClientRect()
    contentHeight = height - idClientRect.top
  }
  return { bodyWidth: width, bodyHeight: height, idClientRect: idClientRect, contentHeight }
}
export function checkAuth(auths: any[]) {
  const user = useUserInfo();
  const userInfo = user.getUserInfo();
  if (!userInfo) return false;
  if (!userInfo.permissions) return false;
  return auths.some(auth => {
    return userInfo.permissions.indexOf(auth) != -1;
  });
}
//计算两个时间相隔多少个小时
function durationHour(start, end) {
  let hoursDiff = 0;
  if (start && end) {
    start = dayjs(start).valueOf();
    end = dayjs(end).valueOf();
    let time = fixedFloat(end, start, "-");
    hoursDiff = dayjs.duration(time).asHours();
    try {
      hoursDiff = Number(hoursDiff.toFixed(2))
    } catch (error) {
      hoursDiff = 0
    }

  }
  return hoursDiff
}
//分钟转小时
function minuteToHour(minutes) {
  let hours = 0;
  const duration = dayjs.duration(minutes, 'minutes')
  hours = Number(duration.asHours().toFixed(2));
  return hours
}
//地址
function addr(address) {
  let str = ""
  if (address) {
    if (address['province']) str = str + address['province']
    if (address['city']) str = str + address['city']
    if (address['area']) str = str + address['area']
    if (address['street']) str = str + address['street']
  }
  return str;
}
//各大进销存变动记录的类型
function formatChangeType(type) {
  let str = "";
  switch (type) {
    case "check":
      str = "盘点";
      break;
    case "transfer":
      str = "调拨";
      break;
    case "production_in":
      str = "生产入库";
      break;
    case "scan_in":
      str = "扫码入库";
      break;
    case "purchase_in":
      str = "采购入库";
      break;
    case "purchase_return":
      str = "采购退货";
      break;
    case "other_in":
      str = "其他入库";
      break;
    case "delete":
      str = "删除库存";
      break;
    case "other_out":
      str = "其他出库";
      break;
    case "sell_out":
      str = "销售出库";
      break;
    case "sell_return":
      str = "销售退货";
      break;
  }
  return str;
}
//绑定
function noBind(obj) {
  const res = obj ? JSON.parse(JSON.stringify(obj)) : "";
  return res;
}
function formatNum(num1, num2, type) {
  return fixedFloat(num1 || 0, num2 || 0, type);
}
function formatPay(type) {
  let text = ""
  switch (type) {
    case 'wxPay':
      text = '微信'
      break;
    case 'cash':
      text = '现金'
      break;
    case 'aliPay':
      text = '支付宝'
      break;
    case 'unPay':
      text = '未支付'
      break;
    case 'bank':
      text = '银行卡'
      break;
    case 'unfinished':
      text = '未付完'
      break;
    default:
      text = '其他'
      break;
  }
  return text
}
function formatDesignStatus(type) {
  let text = ""
  switch (type) {
    case 1:
      text = '已通过'
      break;
    case 2:
      text = '未通过'
      break;
    case 3:
      text = '审核中'
      break;
    case 4:
      text = '设计中'
      break;
    default:
      text = '其他'
      break;
  }
  return text
}
function formatSettleStatus(type) {
  let text = ""
  switch (type) {
    case 0:
      text = '未核对'
      break;
    case 1:
      text = '部分已核对'
      break;
    case 2:
      text = '已核对'
      break;
    default:
      text = '其他'
      break;
  }
  return text
}
function formatAuditStatus(type: number) {
  switch (type) {
    case 1:
      return '已通过'
    case 2:
      return '未通过'
    case 3:
      return '待审核'
    case 4:
      return '设计中'
    default:
      return '其他'
  }
}
function formatLendStatus(type: number) {
  switch (type) {
    case 1:
      return '待归还'
    case 2:
      return '已归还'
    case 3:
      return '已逾期'
    default:
      return '其他'
  }
}
function mhgSetItem(name, obj) {
  const res = obj ? JSON.stringify(obj) : "";
  window.localStorage.setItem(name, res);
}
function mhgGetItem(name, type) {
  const obj = window.localStorage.getItem(name);
  let res = null;
  if (obj) {
    res = JSON.parse(obj);
  } else {
    if (isArray(type)) {
      res = [];
    } else if (isNull(type)) {
      res = null;
    } else if (isObject(type)) {
      res = Object.assign(type, new Object());
    } else {
      res = type;
    }
  }
  return res;
}
function formatSizeKey(data) {
  let allSizes = [];
  for (const color in data) {
    for (const size in data[color]) {
      allSizes.push(size);
    }
  }
  allSizes = uniq(allSizes);
  allSizes = sortSizes(allSizes);
  return allSizes;
}
function getColorAndSize(data, type?: string) {
  let sizes = [];
  let colors = [];
  let newQuantity = {}
  let quantity = {}
  let pendingObj = {}
  if (type == 'array') {
    let colorObj = groupBy(data, 'color')
    for (let key in colorObj) {
      let obj = {}
      colorObj[key].forEach(item => {
        obj[item.size] = item.quantity
      })
      pendingObj[key] = obj
    }
  } else {
    pendingObj = data;
  }
  for (const color in pendingObj) {
    colors.push({ color, select: false })
    let sizeObj = {}
    for (const size in pendingObj[color]) {
      sizes.push({ size, select: false });
      sizeObj[size] = 0;
    }
    newQuantity[color] = sizeObj
  }
  sizes = uniqBy(sizes, 'size');
  colors = uniqBy(colors, 'color');
  return { sizes, colors, newQuantity, quantity: pendingObj };
}
function calTotalColorQuantity(data) {
  let total = 0;
  for (const size in data) {
    total = fixedFloat(total, data[size] || 0, "+");
  }
  return total;
}
function getStaticImage(base, imageUrl, type) {
  let path = ''
  if (type == 'web') {
    let url = ''
    url = base + imageUrl
    const modules: Record<string, any> = import.meta.glob("@/assets/**/*.{png,svg,jpg,jpeg}", { eager: true });
    if (modules[url]) {
      path = modules[url].default
    } else {
      path = ''
    }
  } else if (type == '4G') {
    path = 'https://service.58mhg.com' + imageUrl
  } else {
    path = imageUrl
  }
  return path
}
//过滤订单状态
function orderStatusStr(status) {
  let str = '';
  switch (status) {
    case 0: str = '待排产'; break;
    case 1: str = '生产中'; break;
    case 2: str = '已完成'; break;
    case 3: str = '已出货'; break;
    case 5: str = '已回货'; break;
    default: str = '--';
  }
  return str
}
//过滤订单状态
function sendOrderStatusStr(status) {
  let str = '';
  switch (status) {
    case -1: str = '待删除'; break;
    case 1: str = '待发货'; break;
    case 2: str = '待收货'; break;
    case 3: str = '生产中'; break;
    case 4: str = '已完成'; break;
    case 5: str = '已回货'; break;
    default: str = '--';
  }
  return str
}
//转中文
function digitUppercase(n) {
  const fraction = ['角', '分'];
  const digit = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖'];
  const unit = [['元', '万', '亿'], ['', '拾', '佰', '仟']];
  let num = Math.abs(n);
  let s = '';
  fraction.forEach((item, index) => {
    s += (digit[Math.floor(num * 10 * 10 ** index) % 10] + item).replace(/零./, '');
  });
  s = s || '整';
  num = Math.floor(num);
  for (let i = 0; i < unit[0].length && num > 0; i += 1) {
    let p = '';
    for (let j = 0; j < unit[1].length && num > 0; j += 1) {
      p = digit[num % 10] + unit[1][j] + p;
      num = Math.floor(num / 10);
    }
    s = p.replace(/(零.)*零$/, '').replace(/^$/, '零') + unit[0][i] + s;
  }

  return s
    .replace(/(零.)*零元/, '元')
    .replace(/(零.)+/g, '零')
    .replace(/^整$/, '零元整');
};
//09:00时间形式转为分钟
function timeToMinute(time: string) {
  let timeMinute: number | null = null;
  try {
    let timeArray = time.split(":");
    if (timeArray && timeArray.length > 1) {
      let hour = Number(timeArray[0]);
      let minute = Number(timeArray[1]);
      // if (hour > 24) {
      //   timeMinute = (hour - 24) * 60 + minute;
      // } else {
      //   timeMinute = hour * 60 + minute;
      // }
      timeMinute = hour * 60 + minute;
    }
  } catch (error) {
    timeMinute = null;
  }
  return timeMinute
}
//多少分钟转为时间格式00:00
function minuteToTime(timeMinute: number) {
  let timeStr: string = '';
  if (timeMinute) {
    let hour = ""
    if (Math.floor(timeMinute / 60) < 10) {
      hour = `0${Math.floor(timeMinute / 60)}`
    } else {
      hour = Math.floor(timeMinute / 60) + ''
    }
    let minute = ""
    if (timeMinute % 60 < 10) {
      minute = `0${timeMinute % 60}`
    } else {
      minute = timeMinute % 60 + ''
    }
    timeStr = hour + ":" + minute;
  }
  return timeStr
}
//多少分钟转为时间格式 次日00:00
function showMinuteToTime(timeMinute: number) {
  let timeStr: string = '';
  if (timeMinute) {
    let hourNum = Math.floor(timeMinute / 60)
    let hourStr = ''
    // if (Math.floor(timeMinute / 60) < 10) {
    //   hour = `0${Math.floor(timeMinute / 60)}`
    // } else {
    //   hour = Math.floor(timeMinute / 60) + ''
    // }
    //如果超过24小时，则显示次日
    if (hourNum >= 24) {
      hourStr = '次日' + (hourNum - 24)
    } else {
      if (hourNum < 10) {
        hourStr = `0${hourNum}`
      } else {
        hourStr = `${hourNum}`
      }
    }
    let minute = ""
    if (timeMinute % 60 < 10) {
      minute = `0${timeMinute % 60}`
    } else {
      minute = timeMinute % 60 + ''
    }
    timeStr = hourStr + ":" + minute;
  }
  return timeStr
}

export default {
  install: app => {
    app.config.globalProperties["$digitUppercase"] = digitUppercase;
    app.config.globalProperties["$addr"] = addr;
    app.config.globalProperties["$getInfoById"] = getInfoById;
    app.config.globalProperties["$getInfoByRef"] = getInfoByRef;
    app.config.globalProperties["$orderStatusStr"] = orderStatusStr;
    app.config.globalProperties["$formatDesignStatus"] = formatDesignStatus;
    app.config.globalProperties["$formatSettleStatus"] = formatSettleStatus;
    app.config.globalProperties["$formatAuditStatus"] = formatAuditStatus;
    app.config.globalProperties["$formatLendStatus"] = formatLendStatus;
    app.config.globalProperties["$sendOrderStatusStr"] = sendOrderStatusStr;
    app.config.globalProperties["$checkAuth"] = checkAuth;
    app.config.globalProperties["$noBind"] = noBind;
    app.config.globalProperties["$isEmpty"] = isEmpty;
    app.config.globalProperties["$formatNum"] = formatNum;
    app.config.globalProperties["$formatPay"] = formatPay;
    app.config.globalProperties["$mhgSetItem"] = mhgSetItem;
    app.config.globalProperties["$mhgGetItem"] = mhgGetItem;
    app.config.globalProperties["$formatSizeKey"] = formatSizeKey;
    app.config.globalProperties["$getColorAndSize"] = getColorAndSize;
    app.config.globalProperties["$getStaticImage"] = getStaticImage;
    app.config.globalProperties["$formatChangeType"] = formatChangeType;
    app.config.globalProperties["$calTotalColorQuantity"] =
      calTotalColorQuantity;
    app.config.globalProperties["$timeToMinute"] = timeToMinute;
    app.config.globalProperties["$minuteToTime"] = minuteToTime;
    app.config.globalProperties["$showMinuteToTime"] = showMinuteToTime;
    app.config.globalProperties["$durationHour"] = durationHour;
    app.config.globalProperties["$minuteToHour"] = minuteToHour;
    app.config.globalProperties["$exportBaseUrl"] = exportBaseUrl;
  }
};
