import workflowSvg from "@/assets/svg/workflow.svg";
import { getRank } from '../rank'
let rank = getRank('workflow')
export default {
  path: "/workflow",
  redirect: "/workflow/index",
  name: "workflow_index",
  meta: {
    icon: workflowSvg,
    title: "进度看板",
    rank,
    roles: ['production_router']
  },
  children: [
    {
      path: "/workflow/index",
      name: "workflow",
      component: () => import("@/views/workflow/index.vue"),
      meta: {
        title: "进度看板",
        keepAlive: false,
        roles: ["workflow"]
      }
    }
  ]
} as RouteConfigsTable;
