import { $t } from "@/plugins/i18n";
import syncManage from "@/assets/svg/syncManage.svg";
import send from "@/assets/svg/send.svg";
import recive from "@/assets/svg/recive.svg";
const Layout = () => import("@/layout/index.vue");
import { getRank } from '../rank'
let rank = getRank('syncManage')
export default {
  path: "/syncManage",
  meta: {
    icon: syncManage,
    title: "协同管理",
    rank,
    roles: ['production_router']
  },
  name:"syncManage_index",
  children: [
    {
      path: "/syncManage/send",
      name: "Send",
      component: () => import("@/views/syncManage/send.vue"),
      meta: {
        title: "外发订单",
        keepAlive: false,
        roles: ["outsource_send"],
      }
    },
    {
      path: "/syncManage/receive",
      name: "Receive",
      component: () => import("@/views/syncManage/receive.vue"),
      meta: {
        title: "外接订单",
        keepAlive: false,
        roles: ["outsource_receive"],
      }
    },

  ]
};
