import { http } from "@/utils/http";
import {
  ResultCount,
  ResultList,
  ResultObject,
  ResultStr,
  ResultObjectStyle
} from "./types.d";

//获取鲲贴分类
export const signKunTie = (data?: object) => {
  return http.request<ResultObject>("post", "/main/kun-tie/sign", { data });
};
//获取鲲贴分类
export const getKuntieCategory = (data?: object) => {
  return http.request<ResultList>("post", "/main/kun-tie/label/category", { data });
};
//鲲贴标签列表
export const getLabels = (data?: object) => {
  return http.request<ResultList>("post", "/main/kun-tie/label/list", { data });
};
//鲲贴标签详细
export const getLabelDetail = (data?: object) => {
  return http.request<ResultObject>("post", "/main/kun-tie/label/detail", { data });
};
//获取鲲贴打印数据
export const getPrintTicketLabelData = (data?: object) => {
  return http.request<ResultList>("post", "/main/kun-tie/getPrintTicketLabelData", { data });
};
//鲲贴标签详细
export const printTicket = (data?: object) => {
  return http.request<ResultObject>("post", "/main/kun-tie/printTicket", { data });
};
export const deleteLabel = (data?: object) => {
  return http.request<ResultObject>("post", "/main/kun-tie/label/delete", { data });
};

//获取成品标签数据
export const getStyleProductLabelData = (data?: object) => {
  return http.request<ResultList>("post", "/main/kun-tie/getStyleProductLabelData", { data });
};
//获取成品标签数据
export const printStyleProduct = (data?: object) => {
  return http.request<ResultList>("post", "/main/kun-tie/printStyleProduct", { data });
};
//获取布料标签数据
export const getClothProductLabelData = (data?: object) => {
  return http.request<ResultList>("post", "/main/kun-tie/getClothProductLabelData", { data });
};
//获取布料标签数据
export const printClothProduct = (data?: object) => {
  return http.request<ResultObject>("post", "/main/kun-tie/printClothProduct", { data });
};
//获取辅料标签数据
export const getAccessoryProductLabelData = (data?: object) => {
  return http.request<ResultList>("post", "/main/kun-tie/getAccessoryProductLabelData", { data });
};
//获取辅料标签数据
export const printAccessoryProduct = (data?: object) => {
  return http.request<ResultObject>("post", "/main/kun-tie/printAccessoryProduct", { data });
};
//获取货架标签数据
export const getRackLabelData = (data?: object) => {
  return http.request<ResultList>("post", "/main/kun-tie/getRackLabelData", { data });
};
//发送货架打印数据
export const printRack = (data?: object) => {
  return http.request<ResultObject>("post", "/main/kun-tie/printRack", { data });
};
//获取样衣标签数据
export const getSampleProductLabelData = (data?: object) => {
  return http.request<ResultList>("post", "/main/kun-tie/getSampleProductLabelData", { data });
};
//获取样衣标签数据
export const printSampleProduct = (data?: object) => {
  return http.request<ResultObject>("post", "/main/kun-tie/printSampleProduct", { data });
};
