<template>
  <template v-if="isClomGroup">
    <vxe-colgroup title="">
      <template #header>
        <div class="flex items-center">
          {{ rack_name }}
          <el-button v-if="same_rack_btn" @click="operaSameRack()" type="primary" class="ml-2" link>相同货架</el-button>
        </div>
      </template>
      <vxe-column field="size" title="" min-width="130">
        <template #default="{ row, rowIndex }">
          <el-select v-model="row[props.rack_id]" filterable clearable placeholder="请选择" @change="rackSelect(row, rowIndex)">
            <el-option v-for="item in rackList" :key="item.id" :label="item.rack_name" :value="item.id" />
          </el-select>
        </template>
      </vxe-column>
    </vxe-colgroup>
    <vxe-colgroup title="">
      <template #header>
        <div class="flex items-center">
          <span>{{ location_name }}</span>
          <el-checkbox v-model="verticalCopy" class="ml-3">纵向复制</el-checkbox>
        </div>
      </template>
      <vxe-column field="location" title="" width="200">
        <template #default="{ row, rowIndex }">
          <el-select
            v-model="row[props.location_id]"
            @change="locationSelect(row, rowIndex)"
            @visible-change="(res) => loacationChange(res, row, rowIndex)"
            clearable
            filterable
            placeholder="请选择"
          >
            <el-option v-for="item in !isEmpty(locations) ? locations : row.locations" :key="item.id" :label="item.location_no" :value="item.id" />
          </el-select>
        </template>
      </vxe-column>
    </vxe-colgroup>
  </template>
  <template v-else>
    <vxe-column field="rack" title="货架" width="200">
      <template #header>
        <div class="flex items-center">
          {{ rack_name }}
          <el-button v-if="same_rack_btn" @click="operaSameRack()" type="primary" class="ml-2" link>相同货架</el-button>
        </div>
      </template>
      <template #default="{ row, rowIndex }">
        <el-select v-model="row[props.rack_id]" filterable clearable placeholder="请选择" @change="rackSelect(row, rowIndex)">
          <el-option v-for="item in rackList" :key="item.id" :label="item.rack_name" :value="item.id" />
        </el-select>
      </template>
    </vxe-column>
    <vxe-column field="location" title="库位" width="200">
      <template #header>
        <div class="flex items-center">
          <span>{{ location_name }}</span>
          <el-checkbox v-model="verticalCopy" class="ml-3">纵向复制</el-checkbox>
        </div>
      </template>
      <template #default="{ row, rowIndex }">
        <el-select
          v-model="row[props.location_id]"
          @change="locationSelect(row, rowIndex)"
          @visible-change="(res) => loacationChange(res, row, rowIndex)"
          clearable
          filterable
          placeholder="请选择"
        >
          <el-option v-for="item in !isEmpty(locations) ? locations : row.locations" :key="item.id" :label="item.location_no" :value="item.id" />
        </el-select>
      </template>
    </vxe-column>
  </template>
  <el-dialog append-to-body v-model="sameRackDialog" title="选择货架" class="radius12" width="400px" top="5vh">
    <span class="mr-2">请选择货架</span>
    <el-select v-model="selectSameRack_id" class="!w-[290px]" filterable placeholder="请选择">
      <el-option v-for="item in rackList" :key="item.id" :label="item.rack_name" :value="item.id" />
    </el-select>
    <template #footer>
      <el-button @click="sameRackDialog = false">取消</el-button>
      <el-button type="primary" plain @click="sameRackSelect">确定</el-button>
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import { ref, reactive, onMounted, getCurrentInstance, watch } from "vue";
import { message } from "@/utils/message";
import { isEmpty, find } from "lodash-es";
import { getRackList, getWarehouses, getLocationList } from "@/api/CRM/warehouse";
const emits = defineEmits(["locationChange", "sameRackChange"]);
const props = defineProps({
  from: {
    type: String,
    default: "",
  },
  rack_name: {
    //表头货架名称
    type: String,
    default: "货架",
  },
  location_name: {
    //表头库位名称
    type: String,
    default: "库位",
  },
  rack_id: {
    //绑定的货架id的key值
    type: String,
    default: "rack_id",
  },
  location_id: {
    //绑定的库位id的key值
    type: String,
    default: "location_id",
  },
  warehouse_id: {
    type: Number,
    default: null,
  },
  racks: {
    type: Array,
    default: [],
  },
  rack: {
    type: Object,
    default: {},
  },
  sameRack_id: {
    //相同货架
    type: Number,
    default: null,
  },
  p_idx: {
    //上一级Index
    type: Number,
    default: null,
  },
  same_rack_btn: {
    type: Boolean,
    default: false,
  },
  isClomGroup: {
    //是否有合集表头
    type: Boolean,
    default: false,
  },
});
let locationObj = ref(null);
let sameRackDialog = ref(false);
let selectSameRack_id = ref(null);
function operaSameRack() {
  sameRackDialog.value = true;
}
function sameRackSelect() {
  sameRackDialog.value = false;
  emits("sameRackChange", { rack_id: selectSameRack_id.value, p_idx: props.p_idx });
}
let verticalCopy = ref(true);
let rackList = ref([]);
const onGetRackList = (data) => {
  getRackList(data).then((res) => {
    if (res) {
      rackList.value = res.data;
    }
  });
};
async function getLocations(data) {
  let resData = <any>[];
  resData = await getLocationList(data)
    .then((res) => {
      return res.data;
    })
    .catch(() => {});

  return resData;
}
//库位下拉的时候拉取库位
let locations = ref([]);
const loacationChange = async (res, obj, idx) => {
  if (res && obj[props.rack_id]) {
    locations.value = await getLocations({ rack_id: obj[props.rack_id] });
  }
};
function rackSelect(obj, index) {
  getLocationList({ rack_id: obj[props.rack_id] }).then((res) => {
    if (res) {
      locations.value = res.data;
      obj[props.location_id] = null;
    }
  });
}
function locationSelect(obj, index) {
  let locationObj = find(locations.value, (o) => {
    return o.id == obj[props.location_id];
  });
  emits("locationChange", { data: obj, locationObj, verticalCopy: verticalCopy.value, index, p_idx: props.p_idx });
}
onMounted(() => {
  if (!isEmpty(props.racks)) {
    rackList.value = props.racks;
  } else if (!isEmpty(props.racks) && props.warehouse_id) {
    onGetRackList({ warehouse_id: props.warehouse_id });
  }
});
watch(
  () => props.racks,
  (val) => {
    rackList.value = props.racks;
  },
  { deep: true }
);
</script>
<style scoped></style>
