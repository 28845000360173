<template>
  <template v-if="opearType == 'select'">
    <div
      v-popover="popoverRef"
      :style="{ width: props.width + 'px' }"
      @click="showOrHide"
      class="el-select__wrapper el-tooltip__trigger radius12 flex items-center"
      style="padding: 0px 6px; line-height: 22px"
    >
      <div class="el-select__selection">
        <span v-if="!selectedItems?.length" class="color999">请选择</span>
        <div class="el-select__selected-item el-select__placeholder">
          <el-tag v-for="(tag, index) in selectedItems" :key="tag + ''" class="mt-[-1px] ml-[0px]" :closable="!props.disabled" @close="delSelect(index)">
            {{ tag.name }}
          </el-tag>
        </div>
      </div>
      <div class="el-select__suffix">
        <el-icon><ArrowDown /></el-icon>
      </div>
    </div>
    <el-popover ref="popoverRef" :visible="visible" :width="700" virtual-triggering persistent>
      <div v-loading="loading">
        <div class="text-right mb-3">
          <el-icon class="hover-theme !text-[22px]" @click="visible = false"><CircleClose /></el-icon>
        </div>
        <div class="flex items-center justify-between">
          <div>
            <el-input placeholder="名称" v-model.trim="searchData.keyword" @clear="onSearch" @keyup.enter.native="onSearch" clearable class="!w-[200px]">
              <template #suffix>
                <el-icon @click="onSearch" class="cursor-pointer"><search /></el-icon>
              </template>
            </el-input>
            <el-button @click="onSearch" class="ml-3">搜索</el-button>
          </div>
          <div>
            <el-button type="primary" @click="addCustmerDialog = true" plain>新建</el-button>
          </div>
        </div>
        <el-tabs v-model="curTab" class="demo-tabs" @tab-click="curTabChange">
          <el-tab-pane label="全部" name=""></el-tab-pane>
          <el-tab-pane label="客户" name="客户"></el-tab-pane>
          <el-tab-pane label="加工厂" name="加工厂"></el-tab-pane>
          <el-tab-pane label="供应商" name="供应商"></el-tab-pane>
        </el-tabs>
        <template v-for="item in customerList">
          <el-tag :type="item.tagType" class="mr-[20px] mb-[10px] !min-w-[100px] cursor-pointer" @click="selectItem(item)" size="large">{{ item.name }}</el-tag>
        </template>

        <div class="mt-[10px] w-[100%] overflow-hidden" v-if="pageItem">
          <el-pagination
            class="float-right"
            v-model:current-page="searchData.page_index"
            v-model:page-size="searchData.page_size"
            :page-sizes="[8, 10, 20, 40, 80, 100, 200]"
            layout="total, prev, pager, next, jumper"
            :total="pageItem"
            @size-change="onSizeChange"
            @current-change="onPageChange"
          />
        </div>
      </div>
    </el-popover>
  </template>
  <template v-if="opearType == 'tile'">
    <div class="flex items-center justify-between">
      <div>
        <el-input placeholder="名称" v-model.trim="searchData.keyword" @clear="onSearch" @keyup.enter.native="onSearch" clearable class="!w-[200px]">
          <template #suffix>
            <el-icon @click="onSearch" class="cursor-pointer"><search /></el-icon>
          </template>
        </el-input>
        <el-button @click="onSearch" class="ml-3">搜索</el-button>
      </div>
      <div>
        <el-button @click="addCustmerDialog = true" plain>新建</el-button>
        <el-button type="primary" @click="tileConfirm" plain>确定</el-button>
      </div>
    </div>
    <el-tabs v-model="curTab" class="demo-tabs" @tab-click="curTabChange">
      <el-tab-pane label="全部" name=""></el-tab-pane>
      <el-tab-pane label="客户" name="客户"></el-tab-pane>
      <el-tab-pane label="加工厂" name="加工厂"></el-tab-pane>
      <el-tab-pane label="供应商" name="供应商"></el-tab-pane>
    </el-tabs>
    <template v-for="item in customerList">
      <el-tag :type="item.tagType" class="mr-[20px] mb-[10px] !min-w-[100px] cursor-pointer" @click="selectItem(item)" size="large">{{ item.name }}</el-tag>
    </template>
    <div class="mt-[10px] w-[100%] overflow-hidden" v-if="pageItem">
      <el-pagination
        class="float-right"
        v-model:current-page="searchData.page_index"
        v-model:page-size="searchData.page_size"
        :page-sizes="[8, 10, 20, 40, 80, 100, 200]"
        layout="total, prev, pager, next, jumper"
        :total="pageItem"
        @size-change="onSizeChange"
        @current-change="onPageChange"
      />
    </div>
  </template>
  <manage-customer v-if="addCustmerDialog" @change="customerChange" @close="addCustmerDialog = false"></manage-customer>
</template>
<script setup lang="ts">
import { ref, reactive, onMounted, watch, unref } from "vue";
import { cloneDeep, uniqBy, findIndex } from "lodash-es";
import { nextTick } from "process";
import { DropdownInstance, ElMessage } from "element-plus";
import { getPartnerList, getPartnerCount } from "@/api/customer";
import { isEmpty } from "lodash-es";
import manageCustomer from "@/views/customer/manageCustomer.vue";
import { getPartnerDetail } from "@/api/customer";
const props = defineProps({
  from: {
    type: String,
    default: "",
  },
  // 接收传值
  type: {
    type: String, // 数据类型
    default: "", // 未传值时的默认值
  },
  init: {
    type: Object,
    default: null,
  },
  // 是否可以多选
  multiple: {
    type: Boolean,
    default: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  rowIndex: {
    type: Number,
    default: null,
  },
  norepeat: {
    // 不重复选择
    type: Boolean,
    default: false,
  },
  havedData: {
    // 现有工序
    type: Array,
    default: [],
  },
  curStepId: {
    type: Number,
    default: null,
  },
  partners: {
    //客户信息
    type: Array,
    default: [],
  },
  partner: {
    //客户对象信息
    type: Object,
    default: null,
  },
  partnerId: {
    //客户对象信息
    type: Number,
    default: null,
  },
  partnerCount: {
    type: Number,
    default: 0,
  },
  isGetPartner: {
    //如果为true，则不请求获取接口
    type: Boolean,
    default: false,
  },
  width: {
    //如果为true，则不请求获取接口
    type: Number,
    default: 200,
  },
  opearType: {
    //操作方式
    type: String,
    default: "select", //select选择模式，tile平铺模式
  },
  // showSaveBtn: {
  //   //是否显示保存按钮
  //   type: Boolean,
  //   deault: false,
  // },
});
let visible = ref(false);
const popoverRef = ref();
const onClickOutside = () => {
  // unref(popoverRef).popperRef?.delayHide?.();
};
let addCustmerDialog = ref(false);
function customerChange() {
  addCustmerDialog.value = false;
  onGetCustomerList(searchData, true);
}

const dropdown = ref<DropdownInstance>();

let selectedItems = ref([]);
// 选中项目
function selectItem(item) {
  console.log(JSON.stringify(item));
  if (props.norepeat) {
    // 先找到本条数据
    let selectedStep: any = props.havedData.find((o: any) => o.step_id == props.curStepId);
    console.log("当前 step: ", JSON.stringify(selectedStep));
    console.log("all step: ", JSON.stringify(props.havedData));
    if (selectedStep) {
      // 找相同的 workflow_id 的数据
      const sameWorkflowData = props.havedData
        .filter((v: any) => !!v.workflow_id && v.workflow_id == selectedStep.workflow_id && v.step_id != selectedStep.step_id)
        .find((o: any) => o.partner_id == item.id);
      console.log("same: ", JSON.stringify(sameWorkflowData));
      if (sameWorkflowData) {
        // 有相同 workflow_id 的工序, 判断 customer 是否相同
        return ElMessage.error("相同生产环节不能有相同的合作伙伴");
      }
    }
  }
  confirmSelectCustomer(item);
}

function confirmSelectCustomer(obj) {
  let idx = findIndex(selectedItems.value, (o) => {
    return o.id == obj.id;
  });
  if (idx != -1) {
    selectedItems.value.splice(idx, 1);
  } else {
    selectedItems.value = [obj];
  }
  visible.value = false;
  if (props.opearType == "select") {
    sendInfo();
  } else {
    // getPartnerDetail({ id: obj.id }).then((res) => {
    //   if (res) {
    //     selectedItems.value = [res.data];
    //   }
    // });
    customerList.value = customerList.value.map((item) => {
      if (item.id == obj.id) {
        item.tagType = "primary";
      } else {
        item.tagType = "info";
      }
      return item;
    });
  }
}
function tileConfirm() {
  sendInfo();
}
let curTab = ref("");
function curTabChange({ props }) {
  if (props.name) {
    searchData.types = [props.name];
  } else {
    searchData.types = [];
  }

  onGetCustomerList(searchData, true);
}
function onSearch() {
  searchData.page_index = 1;
  onGetCustomerList(searchData, true);
}
function visibleChange(e) {}
//传递消息给父组件
const emits = defineEmits(["change", "clicksave", "update:partner", "update:partnerId"]);
function sendInfo() {
  getHeight();
  tagHighLight();
  emits("change", { data: selectedItems.value[0], rowIndex: props.rowIndex });
  emits("update:partner", selectedItems.value[0] || []);
  emits("update:partnerId", selectedItems.value[0]?.id || null);
}
//删除选中
function delSelect(index) {
  selectedItems.value.splice(index, 1);
  getHeight();
  sendInfo();
}
//获取高度
function getHeight() {
  nextTick(() => {
    let sc = document.getElementById("select_content" + props.type);
    let si = document.getElementById("select_input" + props.type);
    if (sc && si) {
      if (sc.offsetHeight == 0) {
        si.style.height = "30px";
      } else {
        si.style.height = sc.offsetHeight + 6 + "px";
      }
    }
  });
}
//获取列表
let searchData: any = reactive({
  page_index: 1,
  page_size: 30,
  keyword: "",
  type: 1,
  status: null, // 1: 正常 2: 已申请
  tags: [],
  types: [],
});
let customerList = ref([]);
let loading = ref(false);
function onGetCustomerList(data, isPage) {
  let postData = cloneDeep(data);
  if (postData.status == 4) {
    postData.status = 1;
    postData.is_bind = true;
  }
  loading.value = true;
  getPartnerList(postData)
    .then((res) => {
      if (res) {
        customerList.value = res.data;
        if (props.init) {
          selectedItems.value = [props.init];
          customerList.value = customerList.value.concat([props.init]);
          customerList.value = uniqBy(customerList.value, "id");
        }
        tagHighLight();
        if (isPage) {
          onGetCustomerCount(data);
        }
      }
      loading.value = false;
    })
    .catch(() => {
      loading.value = false;
    });
}
function tagHighLight() {
  customerList.value = customerList.value.map((item) => {
    item.tagType = "info";
    let idx = findIndex(selectedItems.value, (o) => {
      return item.id == o.id;
    });
    if (idx != -1) {
      item.tagType = "primary";
    }
    return item;
  });
}
let pageItem = ref(0);
function onGetCustomerCount(data) {
  getPartnerCount(data).then((res) => {
    if (res) {
      pageItem.value = res.data;
    }
  });
}
// 改变每页显示
function onSizeChange(pageSize) {
  searchData.page_index = 1;
  searchData.page_size = pageSize;
  onGetCustomerList(searchData, false);
}
// 改变每页显示
function onPageChange(pageIndex) {
  searchData.page_index = pageIndex;
  onGetCustomerList(searchData, false);
}
function onGetPartnerDetail() {
  if (!props.partnerId) {
    return;
  }
  getPartnerDetail({ id: props.partnerId }).then((res) => {
    if (res) {
      selectedItems.value = [res.data];
    }
  });
}
function showOrHide() {
  visible.value = !visible.value;
  if (visible.value) {
    onGetCustomerList(searchData, true);
  }
}
onMounted(() => {
  nextTick(() => {
    // if (!isEmpty(props.partner)) {
    //   selectedItems.value = [props.partner];
    // }
    // debugger
    onGetPartnerDetail();
    if (!props.isGetPartner) {
      // onGetCustomerList(searchData, true);
    } else {
      customerList.value = props.partners;
      pageItem.value = props.partnerCount;
      tagHighLight();
    }
  });
});
// watch(
//   () => props.partner,
//   (val) => {
//     if (!isEmpty(val)) {
//       selectedItems.value = [props.partner];
//     } else {
//       selectedItems.value = [];
//     }
//   }
// );
watch(
  () => props.partnerId,
  (val) => {
    onGetPartnerDetail();
  },
  {
    deep: true,
  }
);
</script>
<style scoped>
.mhg-el-select {
  position: relative;
  min-height: var(--el-component-size);
  padding-right: 30px;
  padding-bottom: 4px;
  border: 1px solid var(--el-border-color);
  /* border-radius: var(--el-input-border-radius, var(--el-border-radius-base)); */
  border-radius: 12px;
}

.mhg-el-select .arrow-down {
  position: absolute;
  top: 0;
  right: 10px;
  bottom: 0;
  height: var(--el-component-size);
  margin: auto;
}

.mhg-el-select .arrow-down:hover {
  cursor: pointer;
}
.el-input {
  min-height: var(--el-component-size);
}
.el-select__wrapper:hover {
  box-shadow: 0 0 0 1px var(--el-border-color-hover) inset !important;
}
.el-tag-for-select {
  margin: 0 !important;
}
</style>
