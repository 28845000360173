<template>
  <div>
    <el-select v-model="curPrintId" @change="printerChange" :style="{ width: width }" placeholder="请选择" clearable>
      <el-option v-for="item in pinterList" :key="item.id" :label="item.name" :value="item.id">
        <span class="fl">{{ item.name }}</span>
        <span class="fr ml-6">
          <label v-if="item.online" class="text-green">在线</label>
          <label v-else class="text-info">离线</label>
        </span>
      </el-option>
      <el-option label="去添加" value="gotoAdd" :disabled="true">
        <span v-if="!pinterList.length" class="fl color999">暂无云打印机</span>
        <span class="fr text-mhg addBtn" @click="printDialog = true">添加</span>
      </el-option>
    </el-select>
    <el-dialog append-to-body title="添加云打印机" v-model="printDialog" top="5%" modal-class="overflow-hidden" width="500px" class="mhg-dialog-pd">
      <add-cloud-printer class="mr10" v-if="printDialog" @close="printDialog = false" @change="getAddCloudPrinterChange"> </add-cloud-printer>
    </el-dialog>
  </div>
</template>

<script lang="ts" setup>
import { ref, reactive, onMounted, getCurrentInstance, nextTick } from "vue";

import { getCloudPrintList } from "@/api/cloudPrint";
import addCloudPrinter from "@/components/addCloudPrinter.vue";
import { cainiao } from "@/utils/common-node-service.js";
import { find } from "lodash-es";
import { storageLocal } from "@pureadmin/utils";
import { watch } from "vue";
const props = defineProps({
  from: {
    type: String,
    default: "",
  },
  width: {
    type: String,
    default: "250px",
  },
});
//添加云打印机
let printDialog = ref(false);
//获取打印改变状态
function getAddCloudPrinterChange(res) {
  printDialog.value = false;
  if (res.type == "success") {
    onGetCloudPrintList({});
  }
}

//获取云打印机
let curPrintId = ref(null);
let curPrinter = ref(null);
function printerChange(id) {
  curPrinter.value = find(pinterList.value, (item) => {
    return item.id == id;
  });
  //  保存到本地
  storageLocal().setItem("curPrinter", curPrinter.value);
  sendInfo();
}

//获取打印机列表
const pinterList = ref([]);
function onGetCloudPrintList(data) {
  getCloudPrintList(data).then((res) => {
    pinterList.value = res.data;
    nextTick(() => {
      pinterList.value.forEach((item, index) => {
        checkOnline(item, index);
      });
    });
  });
}
function checkOnline(item, index) {
  const { device_info } = item;
  device_info.printer_id = item.device_id;
  cainiao.printerStatus(device_info).then((res) => {
    pinterList.value[index].online = res.data.online;
  });
  // cainiao
  //   .isOnline({
  //     printerId: device_info.printer_id,
  //     shareCode: device_info.share_code
  //   })
  //   .then(online => {
  //     pinterList.value[index].online = online;
  //   });
}
//传递消息给父组件
const emits = defineEmits(["change"]);
function sendInfo() {
  emits("change", { data: curPrinter.value });
}
onMounted(() => {
  onGetCloudPrintList({});

  //  从本地获取
  try {
    curPrinter.value = storageLocal().getItem("curPrinter");
    if (curPrinter.value) {
      curPrintId.value = curPrinter.value.id;
    }
    sendInfo();
  } catch (e) {}
});

watch(
  () => pinterList.value,
  () => {
    if (curPrinter.value) {
      const printer = pinterList.value.find((s) => s.id == curPrinter.value.id);
      if (!printer) {
        curPrinter.value = null;
        curPrintId.value = null;
        sendInfo();
      }
    }
  }
);
</script>

<style lang="scss" scoped>
.addBtn:hover {
  cursor: pointer;
}
</style>
