<template>
  <div class="inline-block">
    <el-input :placeholder="curSelectObj.placeholder" :style="{ width: width + 'px' }" class="input-with-select" @clear="onSearch" @keyup.enter.native="onSearch" v-model="keyword">
      <template #prepend>
        <el-select slot="prepend" class="!w-[100px] bg-white radius-left-12" v-model="selectKey" @change="selectKeyChange" placeholder="选择类型">
          <el-option v-for="item in typeOptions" :key="item.key" :label="item.name" :value="item.key"> </el-option>
        </el-select>
      </template>
      <template #suffix>
        <el-icon @click="onSearch" class="cursor-pointer"><search /></el-icon>
      </template>
    </el-input>
  </div>
</template>

<script setup lang="ts">
import { ref, reactive, onMounted, getCurrentInstance } from "vue";
const { proxy } = getCurrentInstance();
import { message } from "@/utils/message";
import { isEmpty, find } from "lodash-es";
const props = defineProps({
  showType: {
    type: String,
    default: "category",
  },
  initData: {
    type: Object,
    default: {},
  },
  width: {
    type: Number,
    default: 320,
  },
});
const category = [
  {
    name: "模糊搜索",
    label: "模糊",
    key: "like",
    placeholder: "请输入款式编号/款式名称",
  },
  {
    name: "款式名称",
    label: "款名",
    key: "style_name",
    placeholder: "款式名称",
  },
  {
    name: "款式编号",
    label: "款号",
    key: "style_no",
    placeholder: "款式编号",
  },
  {
    name: "款式颜色",
    label: "颜色",
    key: "color",
    placeholder: "请输入款式颜色",
  },
  {
    name: "备注搜索",
    label: "备注",
    key: "remark",
    placeholder: "请输入备注",
  },
];
const order = [
  {
    name: "模糊搜索",
    label: "模糊",
    key: "like",
    placeholder: "请输入款号/订单号/床次",
  },
  {
    name: "款式编号",
    label: "款号",
    key: "style_no",
    placeholder: "款式编号",
  },
  {
    name: "款式名称",
    label: "款名",
    key: "style_name",
    placeholder: "款式名称",
  },
  {
    name: "订单编号",
    label: "单号",
    key: "order_no",
    placeholder: "订单编号",
  },
  {
    name: "订单床次",
    label: "床次",
    key: "machine",
    placeholder: "订单床次",
  },
  {
    name: "订单颜色",
    label: "颜色",
    key: "color",
    placeholder: "请输入订单颜色",
  },
  {
    name: "订单备注",
    label: "备注",
    key: "remark",
    placeholder: "请输入订单备注",
  },
];
const machine = [
  {
    name: "床次",
    label: "床次",
    key: "machine",
    placeholder: "请输入床次",
  },
  {
    name: "款式编号",
    label: "款号",
    key: "style_no",
    placeholder: "请输入款式编号",
  },
  {
    name: "订单编号",
    label: "单号",
    key: "order_no",
    placeholder: "请输入订单编号",
  },
];
let typeOptions = ref([]);
let selectKey = ref("");
let curSelectObj = ref({
  placeholder: "输入内容回车搜索",
});
let keyword = ref("");
function selectKeyChange() {
  let obj = find(typeOptions.value, (o) => {
    return o.key == selectKey.value;
  });
  if (!isEmpty(obj)) {
    curSelectObj.value = obj;
  }
}
const emits = defineEmits(["change"]);
function onSearch() {
  proxy.$mhgSetItem(props.showType, selectKey.value);
  emits("change", { keyword: keyword.value, key: selectKey.value || "like" });
}
onMounted(() => {
  switch (props.showType) {
    case "showType_category":
      selectKey.value = proxy.$mhgGetItem(props.showType, "like");
      typeOptions.value = category;
      break;
    case "showType_order":
      selectKey.value = proxy.$mhgGetItem(props.showType, "like");
      typeOptions.value = order;
      break;
    case "showType_machine":
      selectKey.value = proxy.$mhgGetItem(props.showType, "machine");
      typeOptions.value = machine;
      break;
  }
  if (props.initData?.key) {
    selectKey.value = props.initData?.key;
  }
  if (props.initData?.keyword) {
    keyword.value = props.initData?.keyword;
  }

  selectKeyChange();
  onSearch();
});
</script>
<style scoped></style>
