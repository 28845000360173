import workflowSvg from "@/assets/svg/workflow.svg";
import { getRank } from '../rank'
let rank = getRank('vip')
export default {
  path: "/vip",
  redirect: "/vip/index",
  meta: {
    icon: workflowSvg,
    title: "进度看板",
    rank
  },
  children: [
    {
      path: "/vip/index",
      name: "Vip",
      component: () => import("@/views/vip/index.vue"),
      meta: {
        title: "Vip",
        keepAlive: false,
        roles: ['vip']
      },
      

    }
  ]
} as RouteConfigsTable;
