import { http } from "@/utils/http";
import {
  ResultCount,
  ResultList,
  ResultObject,
  ResultStr,
  ResultObjectStyle
} from "./types.d";

//保存打印机
export const saveCloudPrint = (data?: object) => {
  return http.request<ResultObject>("post", "/main/device/save", { data });
};
//获取打印机列表
export const getCloudPrintList = (data?: object) => {
  return http.request<ResultList>("post", "/main/device/list", { data });
};
//获取打印机列表
export const deleteCloudPrinter = (data?: object) => {
  return http.request<ResultList>("post", "/main/device/delete", { data });
};

/**
 * 获取打印机设置
 */
export const getSetting = () => {
  return http.request<ResultList>("post", "/main/printer/getSetting");
};

/**
 * 保存打印机设置
 */
export const saveSetting = (data?: object) => {
  return http.request<ResultList>("post", "/main/printer/saveSetting", {
    data
  });
};


//************************************菜鸟打印机服务**************************************
//获取打印机状态
export const printerStatus = (data?: object) => {
  return http.request<ResultObject>("post", "/cainiao/printer-status", { data });
};
//发送验证码
export const sendVerifyCode = (data?: object) => {
  return http.request<ResultObject>("post", "/cainiao/print-verify-code", { data });
};
//绑定打印机
export const bindPrinter = (data?: object) => {
  return http.request<ResultObject>("post", "/cainiao/bind-printer", { data });
};
//打印指令
export const cainiaoPrint = (data?: object) => {
  return http.request<ResultObject>("post", "/cainiao/print-command", { data });
};
