<template>
  <el-collapse class="no-underline-collapse">
    <el-collapse-item :name="item.name" v-for="(item, index) in options" class="mt-3 mr-2" :class="item.parent == 1 ? 'parent' : ''">
      <template #title>
        <template v-if="!props.serachKeyword">
          <div class="flex items-center">
            <span @click.stop="" class="flex items-center mr-3">
              <el-checkbox v-model="item.select" @change="(res) => itemAllSelect(res, item, index)"> </el-checkbox>
            </span>
            <span class="font-bold">
              {{ item.name }}
              <span class="ml-3">{{ calStaffs(item) }}人</span>
            </span>
          </div>
        </template>
        <template v-else>
          <span class="font-bold"
            >{{ item.name }} <span class="ml-3">{{ calStaffs(item) }}人</span></span
          >
        </template>
      </template>
      <div>
        <div class="ml-6 flex items-center flex-wrap hover-theme">
          <el-container
            class="mt-2 flex items-center bg-[#fff] mr-3 relative"
            style="flex: 0 0 120px; border-radius: 50px; border: 1px solid #eee"
            v-for="(staff, staffIndex) in item.staffs"
            v-show="!staff.is_hide"
            @click.stop="selectStaff(item, index, staffIndex)"
          >
            <el-aside width="35" class="h-[35px] !overflow-hidden">
              <mhg-avtar :size="35" :init="staff.user"></mhg-avtar>
            </el-aside>
            <el-main class="!p-0 text-left ml-1 mr-3">
              <div class="text-[#383838]">{{ staff.user.name }}</div>
            </el-main>
            <el-icon v-if="staff.select" class="selected"><SuccessFilled /></el-icon>
          </el-container>
        </div>
        <div class="ml-6">
          <mhg-tree :data="options[index].children" :serachKeyword="props.serachKeyword" :parent="options[index]" @change="getStaffChange"></mhg-tree>
        </div>
      </div>
    </el-collapse-item>
  </el-collapse>
</template>
<script lang="ts">
export default {
  name: "mhgTree",
};
</script>
<script setup lang="ts">
import { ref, reactive, onMounted, getCurrentInstance, watch } from "vue";
const { proxy } = getCurrentInstance();
import { message } from "@/utils/message";
import mhgAvtar from "@/components/mhgAvtar.vue";
import { isEmpty, findIndex, uniqBy } from "lodash-es";
import { emit } from "process";
import { fixedFloat } from "@mhg/utils";
import userDepartmentTree from "@/store/modules/departmentTree";

const props = defineProps({
  from: {
    type: String,
    default: "",
  },
  data: {
    type: Array,
    default: () => {
      return [];
    },
  },
  parent: {
    type: Object,
    default: {},
  },
  serachKeyword: {
    //如果有搜索条件，没有上一级的全选功能，只能一个一个点击
    type: String,
    default: "",
  },
  checkedStaffs: {
    type: Array,
    default: [],
  },
});
const getStaffChange = (res) => {
  // emits("change");

  sendInfo();
};
function sendInfo() {
  userDepartmentTree().setDepartmentTree(options.value);
  emits("change");
}
const emits = defineEmits(["change", "selected"]);
/**选择改变 */
const changeSelct = (data, selected) => {
  data = data.map((item) => {
    item.select = selected;
    if (!isEmpty(item.staffs)) {
      item.staffs = item.staffs.map((staff) => {
        if (!staff.is_hide) {
          staff.select = selected;
        }
        return staff;
      });
    }
    if (!isEmpty(item.children)) {
      changeSelct(item.children, selected);
    }
    return item;
  });
};
/**
 * 全部选择
 */
const itemAllSelect = (selected, obj, index) => {
  if (!isEmpty(obj.children)) {
    changeSelct(obj.children, selected);
  }
  if (!isEmpty(obj.staffs)) {
    obj.staffs = obj.staffs.map((staff) => {
      if (!staff.is_hide) {
        staff.select = selected;
      }
      return staff;
    });
  }
  obj.select = selected;
  // emits("change");
  options.value[index] = obj;
  sendInfo();
};
/**
 * 单个选择
 */
const selectStaff = (obj, pidx, index) => {
  // obj.staffs[index].select = !obj.staffs[index].select;

  options.value[pidx].staffs[index].select = !options.value[pidx].staffs[index].select;
  // emits("change");
  sendInfo();
};

// 计算员工数量
const calStaffs = (data: any) => {
  let count = 0;
  if (data.children) {
    data.children.forEach((item) => {
      count += calStaffs(item);
    });
  }
  count += data.staffs.filter((item) => {
    return !item.is_hide;
  }).length;
  return count;
};
let options = ref([]);
const initCheck = (data, selected) => {
  options.value = options.value.map((item) => {
    if (!isEmpty(item.staffs)) {
      item.staffs = item.staffs.map((staff) => {
        let idx = findIndex(data, (o: any) => {
          return o.user_id == staff.user_id;
        });
        if (idx != -1) staff.select = selected;
        return staff;
      });
    }
    return item;
  });
};
onMounted(() => {
  options.value = proxy.$noBind(props.data);

  setTimeout(() => {
    initCheck(props.checkedStaffs, true);
  }, 1000);
});
watch(
  () => props.data,
  (newModelValue) => {
    options.value = proxy.$noBind(newModelValue);
    // initCheck(props.checkedStaffs, true);
  },
  { deep: true }
);
watch(
  () => props.checkedStaffs,
  (value) => {
    // initCheck(props.checkedStaffs, true);
  },
  { deep: true, immediate: true }
);
</script>
<style scoped>
:deep(.parent .el-collapse-item__header) {
  border: 1px solid #e5e5e5;
  border-radius: 12px;
  padding: 0 10px;
}
:deep(.parent .el-collapse-item__header:first-child.is-active) {
  border: #fafafa;
  background: #fafafa;
}
:deep(.el-collapse-item__content),
:deep(.el-collapse-item.is-active) {
  background: #fafafa;
  border-radius: 12px;
}
:deep(.el-collapse-item__wrap) {
  border-radius: 12px;
}
:deep(.el-collapse-item__content) {
  padding-bottom: 10px;
}
:deep(.el-collapse-item__wrap) {
  border: none !important;
}
:deep(.el-collapse-item__content),
:deep(.el-collapse-item),
:deep(.el-collapse) {
  border: none !important;
}
.selected {
  font-size: 20px;
  color: #ff6600;
  position: absolute;
  right: -5px;
  top: -5px;
}
</style>
