import { defineStore } from 'pinia'
import axios from "axios";
import { checkAuth } from "@/utils/util"
import { useUserInfo } from "@/store/modules/useInfo";

const userSystemDataStore = defineStore('systemData', {
  state: () => ({
    data: {}
  }),
  getters: {},
  actions: {
    async setSystemData() {
      try {
        //获取系统的一些基础配置信息
        const resConstants = await axios.post("/main/common/constants");
        const resStaff = await axios.post("/main/staff/getStaffList", { is_duty: true, page_index: 1, page_size: 1500 });
        const resDepart = await axios.post("/main/department/getList");
        //如果有权限，则要先验证权限
        const user = useUserInfo();
        const userInfo = user.getUserInfo();
        let resPartner = { data: { data: [] } }
        if (userInfo && !checkAuth(['partner_view', 'order', 'style', 'accessory', 'cloth', 'product', 'design_style'])) {
          resPartner = await axios.post("/main/partner/getList");
        };

        let obj = {
          constants: resConstants?.data?.data,
          staffs: resStaff?.data?.data,
          departments: resDepart?.data?.data,
          partners: resPartner?.data?.data
        }
        // this.data = resConstants.data.data;
        this.data = obj;
      } catch (error) {
        console.log(error)
      }

    },
    getSystemData() {
      return this.data;
    },
  }
})
export default userSystemDataStore