import axios from "axios";
const rootUrl = "https://service.jijianwang.cn";
const rootUrl2 = "https://service.58mhg.com";
export function post(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .post(rootUrl + url, params, {
        headers: {
          "content-type": "application/json; charset=utf-8"
        }
      })
      .then(res => {
        resolve(res.data);
      })
      .catch(reject);
  });
}
export function post2(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .post(rootUrl2 + url, params, {
        headers: {
          "content-type": "application/json; charset=utf-8"
        }
      })
      .then(res => {
        resolve(res.data);
      })
      .catch(reject);
  });
}

export function get(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .get(rootUrl + url, {
        params,
        headers: {
          "content-type": "application/json; charset=utf-8"
        }
      })
      .then(res => {
        resolve(res.data);
      })
      .catch(reject);
  });
}
export function get2(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .get(rootUrl2 + url, {
        params,
        headers: {
          "content-type": "application/json; charset=utf-8"
        }
      })
      .then(res => {
        resolve(res.data);
      })
      .catch(reject);
  });
}

export const cainiao = {
  printerStatus: function (data) {
    return new Promise((resolve, reject) => {
      post("/cainiao/printer-status", data)
        .then(res => {
          const { code, errorMsg } = res;
          if (code == 200) resolve(res);
          else reject(errorMsg || "打印失败");
        })
        .catch(reject);
    });
  },
  sendVerifyCode: function (data) {
    return new Promise((resolve, reject) => {
      post("/cainiao/print-verify-code", data)
        .then(res => {
          const { code, errorMsg } = res;
          if (code == 200) resolve(res);
          else reject(errorMsg || "打印失败");
        })
        .catch(reject);
    });
  },
  bindPrinter: function (data) {
    return new Promise((resolve, reject) => {
      post("/cainiao/bind-printer", data)
        .then(res => {
          const { code, errorMsg } = res;
          if (code == 200) resolve(res);
          else reject(errorMsg || "绑定失败");
        })
        .catch(reject);
    });
  },
  cainiaoPrint: function (data) {
    return new Promise((resolve, reject) => {
      post("/cainiao/print-command", data)
        .then(res => {
          const { code, errorMsg } = res;
          if (code == 200) resolve(res);
          else reject(errorMsg || "打印失败");
        })
        .catch(reject);
    });
  },
  printTickets: function (data) {
    return new Promise((resolve, reject) => {
      post2("/remote_print/cainiao/jjw/printTickets", data)
        .then(res => {
          const { isSuccess, errorMsg } = res;
          if (isSuccess == "true") resolve(true);
          else reject(errorMsg || "打印失败");
        })
        .catch(reject);
    });
  },
  orderPrint: function ({ printerId, shareCode, data }) {
    return new Promise((resolve, reject) => {
      post2("/remote_print/cainiao/order/print", { printerId, shareCode, data })
        .then(res => {
          const { isSuccess, errorMsg } = res;
          if (isSuccess == "true") resolve(true);
          else reject(errorMsg || "打印失败");
        })
        .catch(reject);
    });
  },
  isOnline: function ({ printerId, shareCode }) {
    return new Promise((resolve, reject) => {
      post2("/remote_print/cainiao/isOnline", { printerId, shareCode })
        .then(res => {
          resolve(res.isSuccess == "true");
        })
        .catch(reject);
    });
  },
  labelCommand: function ({ printerId, shareCode, queueId, printerSetting }) {
    return new Promise((resolve, reject) => {
      post2("/remote_print/cainiao/labelCommand", {
        printerId,
        shareCode,
        queueId,
        printerSetting
      })
        .then(res => {
          resolve(res.isSuccess == "true");
        })
        .catch(reject);
    });
  },
  getTemplates() {
    return new Promise((resolve, reject) => {
      get2("/command_template/jjw/template-list.json", null)
        .then(res => {
          resolve(res);
        })
        .catch(reject);
    });
    // var url = '/command_template/cloth/template-list.json?r=' + Math.random();
    // axios.get(url).then(res => {
    //   this.printTemplateOptions = res.data.templates
    // }).catch(error => {
    //   console.log(error);
    // });
  }
};

export const url2img = {
  file: function (url) {
    return new Promise((resolve, reject) => {
      get2("/url2img/file", { url })
        .then(res => {
          resolve(res.path);
        })
        .catch(reject);
    });
  },
  download: function (url) {
    return new Promise((resolve, reject) => {
      if (typeof plus == "undefined") reject("no plus");
      const time = new Date().getTime();
      const filename = `_downloads/${time}.png`;
      const dtask = plus.downloader.createDownload(
        rootUrl2 + "/url2img/stream?url=" + encodeURIComponent(url),
        {
          method: "GET",
          filename: filename
        },
        (d, status) => {
          // 下载完成
          if (status == 200) {
            console.log("Download success: " + d.filename);
            resolve(d.filename);
          } else {
            reject(status);
            console.log("Download failed: " + status);
          }
        }
      );
      dtask.start();
    });
  }
};
