import { $t } from "@/plugins/i18n";
import attendance from "@/assets/svg/attendance.svg";
const Layout = () => import("@/layout/index.vue");
import { getRank } from '../rank'
let rank = getRank('attendance')
export default {
  path: "/attendance",
  name: "attendance_index",
  meta: {
    icon: attendance,
    title: "考勤管理",
    rank,
    roles: ['attendance_router']
  },
  children: [
    {
      path: "/attendance/overview",
      name: "Overview",
      component: () => import("@/views/attendance/overView/index.vue"),
      meta: {
        title: "总览",
        keepAlive: false,
      }
    },
    {
      path: "/attendance/rule",
      name: "Rule",
      component: () => import("@/views/attendance/rule/index.vue"),
      meta: {
        title: "考勤规则",
        keepAlive: false
      }
    },
    {
      path: "/attendance/shift",
      name: "Shift",
      component: () => import("@/views/attendance/shiftManager/index.vue"),
      meta: {
        title: "班次管理",
        keepAlive: false
      }
    },
    {
      path: "/attendance/appove",
      name: "Appove",
      component: () => import("@/views/attendance/appoveManager/index.vue"),
      meta: {
        title: "审批管理",
        keepAlive: false
      }
    },
    {
      path: "/attendance/holiday",
      name: "Holiday",
      component: () => import("@/views/attendance/holidayManager/index.vue"),
      meta: {
        title: "假期管理",
        keepAlive: false
      }
    },
    {
      path: "/attendance/statement/index",
      name: "Statement",
      component: () => import("@/views/attendance/statement/index.vue"),
      meta: {
        title: "报表",
        keepAlive: false
      }
    },

  ]
};
