<template>
  <div class="">
    <el-date-picker
      v-model="searchDataTime"
      :clearable="showClear"
      @change="onDateChange"
      type="daterange"
      :style="{ width: dateTimeWidth }"
      unlink-panels
      range-separator="至"
      start-placeholder="开始时间"
      end-placeholder="结束时间"
      :shortcuts="shortcuts"
      :class="props.customClass"
    />
  </div>
</template>
<script lang="ts" setup>
import { ref, reactive, onMounted, watch } from "vue";
import { isEmpty } from "lodash-es";
import dayjsO from "dayjs";
import { message } from "@/utils/message";
const props = defineProps({
  dateTimeWidth: {
    type: String,
    default: "250px",
  },
  firstNone: {
    type: Boolean,
    default: false,
  },
  showClear: {
    type: Boolean,
    default: false,
  },
  model: {
    type: Object,
    default: {},
  },
  config: {
    //配置开始时间和默认时间字段
    type: Object,
    default: {
      start: "start_date",
      end: "end_date",
    },
  },
  customClass: {
    type: String,
    default: "",
  },
  month: {
    //默认显示多少个月份
    type: Number,
    default: 0,
  },
});
const shortcuts = [
  {
    text: "最近一周",
    value: () => {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
      return [start, end];
    },
  },
  {
    text: "上一个月",
    value: () => {
      let curdate = "";
      if (!isEmpty(searchDataTime.value)) {
        curdate = searchDataTime.value[0];
      } else {
        return message("没有基础时间");
      }
      const start = dayjsO(curdate).add(-1, "month").startOf("month");
      const end = dayjsO(curdate).add(-1, "month").endOf("month");
      return [start, end];
    },
  },
  {
    text: "当前月",
    value: () => {
      const start = dayjsO().startOf("month");
      const end = dayjsO().endOf("month");
      return [start, end];
    },
  },
];
function onDateChange(date) {
  sendInfo();
}
const searchDataTime = ref(null);
//传递消息给父组件
const emits = defineEmits(["change"]);
function sendInfo() {
  let start_date = null;
  let end_date = null;
  if (!isEmpty(searchDataTime.value)) {
    start_date = dayjsO(searchDataTime.value[0]).format("YYYY-MM-DD");
    end_date = dayjsO(searchDataTime.value[1]).format("YYYY-MM-DD");
  }

  emits("change", { start_date, end_date });
}
onMounted(() => {
  const now = dayjsO();

  // const startTime = dayjsO().startOf("month");
  const startTime = now.subtract(props.month, "month").startOf("month");
  const endTime = dayjsO().endOf("month");
  if (!isEmpty(props.model)) {
    let start = props.config.start;
    let end = props.config.end;
    searchDataTime.value = [props.model[start], props.model[end]];
  } else if (!props.firstNone) {
    searchDataTime.value = [startTime, endTime];
  }
});

const clearSelection = () => {
  searchDataTime.value = null;
};

defineExpose({
  clearSelection,
});
watch(
  () => props.model,
  (val) => {
    let start = props.config.start;
    let end = props.config.end;
    searchDataTime.value = [props.model[start], props.model[end]];
  },
  { deep: true, immediate: true }
);
</script>
